import { isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { uiActions } from "../states";

export const rtkQueryUIMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("We got a rejected action!");
      api?.dispatch(
        uiActions?.showError(
          action?.payload?.data?.message || "Oops! Something went wrong!",
        ),
      );
    } else if (isFulfilled(action)) {
      const endpoint: string = action?.meta?.arg?.endpointName;
      if (endpoint && !endpoint.startsWith("get")) {
        api?.dispatch(
          uiActions?.showSuccess(
            `${endpoint.replace(
              /([a-z])([A-Z])/g, //replace camel case and add "d" (i.e createUser = created User)
              "$1 $2",
            )} was successful`,
          ),
        );
      }
    }
    return next(action);
  };
