import React, { useEffect, useRef } from "react";
import { Button, Box, Typography, useTheme, Link } from "@mui/material";
import { Form, Navigation, useFormik, yup } from "../../lib";
import { AppThunkDispatch, authActions, useDispatch } from "../../state";
import { ContainerStyle, FormStyle } from "./Auth.styles";
import { actions } from "../../state/rtk-query";
import { AuthState } from "../../state/auth/state";
import { useMobile } from "../../themes";
import { CodeField } from "../../components/fields/CodeField";
import { useSearchParams } from "react-router-dom";
import AdminPages from "../admin";

const validationSchema = yup.object({
  phone: yup
    .string()
    .min(10, "Phone should be of minimum 10 characters length")
    .required("Phone is required"),
  otp_code: yup
    .string()
    .min(6, "Otp code should be 6 characters long")
    .required("Otp code is required"),
});

export const CodeSentPage = React.memo(function LoginPage() {
  const { useAuthLoginMutation, useRequestOtpCodeMutation } = actions;
  const [login, { isSuccess, data }] = useAuthLoginMutation();
  const [requestOtp, { isLoading: requestOtpLoading }] =
    useRequestOtpCodeMutation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams, _setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isSuccess && data) {
      const { token } = data;
      if (token) {
        const authState: AuthState = {
          token,
        };
        dispatch(authActions.setAuthState(authState));
        Navigation.go(AdminPages.dashboard.path);
      }
    }
  }, [isSuccess]);
  const frm = useFormik({
    initialValues: {
      phone: "",
      otp_code: "",
    },
    validationSchema,
    async onSubmit(values) {
      await login({ body: values });
      // if (!isSuccess) {
      //   frm.setFieldValue("otp_code", "");
      // }
    },
  });
  useEffect(() => {
    const phone = searchParams.get("phone");
    if (phone) {
      frm.setFieldValue("phone", phone);
    }
  }, [searchParams.get("phone")]);
  const targetRef = useRef<HTMLHeadingElement>(null);
  const isMobile = useMobile();
  const theme = useTheme();

  return (
    <ContainerStyle>
      <Box
        sx={{
          width: 1,
          borderRadius: { xs: "20px 20px 0 0", sm: "20px" },
          backgroundColor: "#FFFFFF",
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          fontWeight={500}
          fontSize={isMobile ? 24 : 28}
          sx={{ mt: 4 }}
          textAlign="left"
        >
          Code sent
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          variant="body1"
          gutterBottom
          fontWeight={400}
          textAlign="left"
        >
          We sent a code to your phone to verify your identity. Please enter the
          code to continue.
        </Typography>
        <FormStyle>
          <Form form={frm}>
            <CodeField />
            <div ref={targetRef}>
              <Button
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting || !frm.isValid}
                fullWidth
                sx={{ mt: 3 }}
              >
                Verify your number
              </Button>
              <Box display="flex" mt={2}>
                <Typography>Didn’t get a code?</Typography>
                <Link
                  style={{
                    color: requestOtpLoading
                      ? theme.palette.text.disabled
                      : theme.palette.primary.main,
                    textDecoration: "none",
                    textAlign: "right",
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "24px",
                    font: "Graphik Semibold",
                    marginLeft: 4,
                  }}
                  // disabled={requestOtpLoading}
                  href="#"
                  //TODO: Resend code
                  onClick={() =>
                    !requestOtpLoading &&
                    requestOtp({ body: { phone: frm.values.phone } })
                  }
                >
                  Resend code
                </Link>
              </Box>
            </div>
          </Form>
        </FormStyle>
      </Box>
    </ContainerStyle>
  );
});
