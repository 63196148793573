/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Common environment names. */
const Environments = {
  development: "development",
  production: "production",
};

/** Name of the current environment. */
const NODE_ENV = process.env.NODE_ENV || Environments.development;
/** Host name used to detect production mode. */
const VITE_PROD_HOSTNAME = import.meta.env.VITE_PROD_HOSTNAME;
/** True if the app is in development mode. */
const __DEV__ = NODE_ENV === Environments.development;
/**
 * True if the app is in production mode.
 * NOTE: We don't use Environments.production to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
const __PROD__ = window.location.hostname === VITE_PROD_HOSTNAME;
const COLLAPSIBLE_DRAWER = import.meta.env.COLLAPSIBLE_DRAWER || true;
/** Base URL of the API. */
const API_BASE_URL = import.meta.env.VITE_API_URL;
/** URL to the company website. */
const VITE_COMPANY_SITE_URL = import.meta.env.VITE_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const VITE_ERRORS_DSN = import.meta.env.VITE_ERRORS_DSN;
/** True to report errors during development. */
const VITE_ERRORS_DEV = import.meta.env.VITE_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const VITE_PERSIST_KEY = import.meta.env.VITE_PERSIST_KEY;
/** Site title string. */
const VITE_SITE_TITLE = import.meta.env.VITE_SITE_TITLE;
/** Package version string from the package.json file. */
const VITE_VERSION = import.meta.env.VITE_VERSION;
/** Google client id */
const VITE_GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID || "";
/** Microsoft client id */
const VITE_MICROSOFT_CLIENT_ID = import.meta.env.VITE_MICROSOFT_CLIENT_ID || "";
const VITE_MICROSOFT_TENANT_ID = import.meta.env.VITE_MICROSOFT_TENANT_ID;
const MFA_Active = import.meta.env.VITE_MFA === "on";
const VITE_MUI_LICENSE = import.meta.env.VITE_MUI_LICENSE;
const VITE_CLIENT_ID = import.meta.env.VITE_CLIENT_ID || "";
const VITE_AAD_ENDPOINT = import.meta.env.VITE_AAD_ENDPOINT || "";
const VITE_TENANT_ID = import.meta.env.VITE_TENANT_ID;
/** Google Maps API key */
const VITE_MAPS_API_KEY = import.meta.env.VITE_MAPS_API_KEY;

export {
  __DEV__,
  __PROD__,
  VITE_GOOGLE_CLIENT_ID,
  VITE_MICROSOFT_CLIENT_ID,
  VITE_MICROSOFT_TENANT_ID,
  NODE_ENV,
  API_BASE_URL,
  VITE_COMPANY_SITE_URL,
  VITE_ERRORS_DSN,
  VITE_ERRORS_DEV,
  VITE_PERSIST_KEY,
  VITE_PROD_HOSTNAME,
  VITE_SITE_TITLE,
  VITE_VERSION,
  COLLAPSIBLE_DRAWER,
  MFA_Active,
  VITE_MUI_LICENSE,
  VITE_CLIENT_ID,
  VITE_AAD_ENDPOINT,
  VITE_TENANT_ID,
  VITE_MAPS_API_KEY,
};
