import { useEffect, memo } from "react";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { RootStyle, OutletPanel } from "./AuthLayout.styles";
import { useSelector } from "react-redux";
import { authSelectors, uiSelectors } from "../../state";
import { Navigation, getQueryParam } from "../../lib";
import { Backdrop, CircularProgress } from "@mui/material";
import logo from "../../assets/svg/logo.svg";
import group from "../../assets/svg/group.svg";
import { useMobile } from "../../themes";

export const AuthLayout = memo(function AuthLayout() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const requiresMfa = useSelector(authSelectors.requiresMfa);
  const loading = useSelector(uiSelectors.loading);
  const isMobile = useMobile();
  useEffect(() => {
    if (isLoggedIn === true && requiresMfa === false) {
      setTimeout(() => {
        Navigation.replace(getQueryParam("after") || "/admin");
      }, 750);
    } else if (requiresMfa === true) {
      Navigation.go("/auth/multi-factor-auth");
    }
  }, [isLoggedIn, requiresMfa]);
  return (
    <RootStyle>
      <img
        src={logo}
        style={{ margin: 40, height: 56 }}
        alt="instaraise logo"
      />
      {!isMobile && (
        <img src={group} style={{ height: 172 }} alt="instaraise group" />
      )}
      <OutletPanel>
        {isMobile && <img src={group} alt="instaraise group" />}
        <Outlet />
      </OutletPanel>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
});
