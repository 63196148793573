import { Navigation } from "../../../lib";
import { api } from "../api";
export const addTagTypes = ["cards", "card_details"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getCards: build.query<GetCardsApiResponse, GetCardsApiArg>({
        query: () => ({ url: `/discount_cards/user_card_list` }),
        providesTags: ["cards"],
      }),
      getCard: build.query<GetCardApiResponse, GetCardApiArg>({
        query: ({ id }) => ({ url: `/discount_cards/${id}/user_card_details` }),
        providesTags: (_result, _error, arg) => [
          { type: "card_details", id: arg.id },
        ],
      }),
      transferCard: build.mutation<GenericApiResponse, TransferCardApiArg>({
        query: ({ id, body }) => ({
          url: `/discount_cards/${id}/transfer_card`,
          method: "POST",
          body,
        }),
        onQueryStarted: async (arg, { queryFulfilled }) => {
          try {
            await queryFulfilled;
            Navigation.go("/admin");
          } catch (error) {
            console.error(error);
          }
        },
        invalidatesTags: ["cards"],
      }),
      redeemOffer: build.mutation<GenericApiResponse, RedeemOfferApiArg>({
        query: ({ body }) => ({
          url: `/discount_card_offers/redeem`,
          method: "POST",
          body,
        }),

        invalidatesTags: (_result, _error, arg) => [
          "cards",
          { type: "card_details", id: arg.id },
        ],
      }),
      activateNewCard: build.mutation<
        ActivateNewCardResponse,
        ActivateNewCardApiArg
      >({
        query: ({ body }) => ({
          url: `/discount_cards/activate_customer_card_with_logged_in_user`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["cards"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };

export type ActivateNewCardApiArg = {
  body: {
    activation_code: string;
  };
};
export type GetCardsApiResponse = /** status 200 Cards */ {
  id: number;
  card_image: string;
  card_name: string;
  display_estimated_values: boolean;
  estimated_card_value: string;
  estimated_redeemed_value: number; //TODO: fix this type - will be string
  expires_on: string | Date;
}[];

export type GetCardsApiArg = void;

export type GetCardApiResponse = {
  isPreviewMode?: boolean;
  card_image: string;
  org_logo: null | string;
  org_picture: null | string;
  support_logo: string;
  id: number;
  expires_on: string;
  display_estimated_values: boolean;
  estimated_card_value: string;
  org_name: null | string;
  card_name: null | string;
  org_paragraph: null | string;
  org_video_link: null | string;
  support_name: null | string;
  support_phone: null | string;
  support_email: null | string;
  support_message: null | string;
  categories: {
    id: number;
    name: string;
  }[];
  categorizedMerchants: {
    categoryId: number;
    category_name: string;
    merchants: {
      merchantId: number;
      logo: null | string;
      display_name: string;
      categoryId: number;
      category_name: string;
      locations: {
        id: number;
        location_name: null | string;
        address: string;
        city: string;
        state: string;
        zip: string;
        latitude: number;
        longitude: number;
        website?: string;
        phone?: string;
      }[];
      offers: {
        id: number;
        offer_name: string;
        custom_expiration: null | string;
        number_of_uses: number | null;
        restrictions_text: null | string;
        estimated_value_per_use: string;
        redemption_method: "Manual" | "Code" | "Qr code" | "Barcode";
        redemption_code: null | string;
        used_count: number;
      }[];
    }[];
  }[];
  merchantPreviews: {
    id: number;
    display_name: string;
    logo: null | string;
  }[];
};

export type TransferCardApiArg = {
  id: number;
  body: {
    phone: string;
  };
};
export type GetCardApiArg = { id: number };
export type RedeemOfferApiArg = {
  id: number;
  body: {
    id: number;
  };
};
export type GenericApiResponse = { message: string; status: number };

export type ActivateNewCardResponse = {
  discount_card_id: number;
};

export const {
  /** getCards */
  useGetCardsQuery,
  /** getCard */
  useGetCardQuery,
  /** transferCard */
  useTransferCardMutation,
  /** redeemOffer */
  useRedeemOfferMutation,
  /** activateNewCard */
  useActivateNewCardMutation,
} = injectedRtkApi;
