import { useMemo } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Drawer,
  Container,
  IconButton,
  styled,
} from "@mui/material";
import { Navigation, useHash } from "../../lib/routing";
import { TeamMessage } from "../../components/drawer/TeamMessage";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import GroupsIcon from "@mui/icons-material/GroupsOutlined";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { ContactSupport } from "../../components/drawer/ContactSupport";
import { TransferCard } from "../../components/drawer/TransferCard";
import AdminPages from ".";
import { useParams } from "react-router-dom";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: 16,
  fontSize: 16,
  height: 64,
  color: theme.palette.text.secondary,
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  position: "fixed",
  zIndex: 1,
  right: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  paddingRight: 16,
  width: "100%",
  background: `linear-gradient(to bottom,#ffffff 50%, rgba(255, 255, 255, 0.71) 81.97%, rgba(255, 255, 255, 0) 100%);`,
  ...theme.mixins.toolbar,
}));

const ProfilePage = () => {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });

  const showOurMessageButton = useMemo(() => {
    return !!(data?.org_picture || data?.org_video_link || data?.org_paragraph);
  }, [data]);
  const showContactSupportButton = useMemo(() => {
    return !!(data?.support_email || data?.support_phone);
  }, [data]);
  const [hash, setHash] = useHash();
  const isRNWebView = useMemo(() => {
    return window.isRNWebView;
  }, []);
  return (
    <Container maxWidth="md">
      {showOurMessageButton ? (
        <Card sx={{ mb: 2 }}>
          <CardActionArea onClick={() => setHash("message")}>
            <StyledCardContent>
              <GroupsIcon />
              Our message
            </StyledCardContent>
          </CardActionArea>
        </Card>
      ) : null}
      {showContactSupportButton ? (
        <Card sx={{ mb: 2 }}>
          <CardActionArea onClick={() => setHash("contact")}>
            <StyledCardContent>
              <ContactSupportOutlinedIcon />
              Contact support
            </StyledCardContent>
          </CardActionArea>
        </Card>
      ) : null}
      <Card sx={{ mb: 2 }}>
        <CardActionArea onClick={() => setHash("transfer")}>
          <StyledCardContent>
            <CachedOutlinedIcon />
            Transfer card
          </StyledCardContent>
        </CardActionArea>
      </Card>
      {isRNWebView ? (
        <Card sx={{ mb: 2 }}>
          <CardActionArea
            LinkComponent="a"
            href="https://www.instaraise.com/privacy"
            target="_blank"
          >
            <StyledCardContent>
              <PrivacyTipIcon />
              Privacy policy
            </StyledCardContent>
          </CardActionArea>
        </Card>
      ) : null}
      <Card sx={{ mb: 2 }}>
        <CardActionArea onClick={() => Navigation.go(AdminPages.logout.path)}>
          <StyledCardContent>
            <LogoutOutlinedIcon />
            Log out
          </StyledCardContent>
        </CardActionArea>
      </Card>
      <Drawer
        anchor="bottom"
        open={!!hash}
        onClose={() => setHash("")}
        PaperProps={{
          sx: {
            px: 2,
            pb: 4,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            maxHeight: "75vh",
          },
        }}
      >
        <DrawerHeader>
          <IconButton size="small" onClick={() => Navigation.history.back()}>
            <CloseOutlinedIcon />
          </IconButton>
        </DrawerHeader>
        {hash === "message" && <TeamMessage />}
        {hash === "contact" && <ContactSupport />}
        {hash === "transfer" && <TransferCard />}
      </Drawer>
    </Container>
  );
};

export default ProfilePage;
