import { Box, Button, Container, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import activate_card from "../../assets/svg/activate_card.svg";
import { Form, Navigation, useFormik, yup } from "../../lib";
import { FormStyle } from "../../pages/auth/Auth.styles";
import { useContext, useEffect, useRef } from "react";
import { actions } from "../../state/rtk-query";
import { ActivationCodeField } from "../fields/ActivationCodeField";
import AdminPages from "../../pages/admin";
import { NewCardContext } from "../../context";

const { useActivateNewCardMutation, useGetCardQuery } = actions;
const validationSchema = yup.object({
  activation_code: yup
    .string()
    .min(9, "Activation code should be 9 characters long")
    .required("Activation code is required"),
});

export function ActivateNewCard() {
  const { id } = useParams();
  const [, showActivateCard] = useContext(NewCardContext);
  const [activateNewCard, { isSuccess, data: newCard }] =
    useActivateNewCardMutation();
  const { data } = useGetCardQuery({ id: Number(id) }, { skip: !id });
  const frm = useFormik({
    initialValues: {
      activation_code: "",
    },
    validationSchema,
    async onSubmit(values) {
      await activateNewCard({
        body: { activation_code: values.activation_code },
      });
      if (!isSuccess) {
        frm.setFieldValue("agree_to_card_transfer", false);
      }
    },
  });
  const targetRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    if (isSuccess && newCard?.discount_card_id) {
      Navigation.go(
        AdminPages.card.path.replace(":id", String(newCard.discount_card_id)),
      );
      showActivateCard(false);
    }
  }, [isSuccess, newCard]);
  return (
    <Container maxWidth="md">
      <FormStyle>
        <Form form={frm}>
          <Box sx={{ mt: 6, display: "flex", flexDirection: "column", gap: 2 }}>
            <Box display="flex" justifyContent="center" marginBottom={5}>
              <img src={activate_card} width={180} about="" alt="" />
            </Box>
            <Typography variant="h6">Activate new card</Typography>
            <Typography variant="body1">
              Enter the activation code that you received.
            </Typography>
            <ActivationCodeField />
            <Tooltip
              title={
                data?.isPreviewMode
                  ? "Card activation disabled in preview mode"
                  : ""
              }
              enterTouchDelay={0}
              style={{ zIndex: 1 }}
              placement="top-start"
              arrow
            >
              <div ref={targetRef}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    data?.isPreviewMode ||
                    !frm.dirty ||
                    !frm.isValid ||
                    frm.isSubmitting
                  }
                  fullWidth
                  sx={{
                    mt: 3,
                  }}
                >
                  Activate card
                </Button>
              </div>
            </Tooltip>
          </Box>
        </Form>
      </FormStyle>
    </Container>
  );
}
