import { RootStyle } from "./ErrorPage.styles";
// import errorSomethingWrong from "../../assets/img/error_car_upturned.svg";

export function ErrorPage() {
  return (
    <RootStyle>
      <div className="paper">
        {/* <img src={errorSomethingWrong} alt="error-something-wrong-svg"></img> */}
        <h3 className="something-went-wrong">Something went wrong</h3>
        <p className="were-not-sure-exact">
          The system has encountered an exception
        </p>
        {/* <p className="were-not-sure-exact">
        Please contact an Employee for assistance
      </p> */}
      </div>
    </RootStyle>
  );
}
