import { styled } from "@mui/material/styles";

export const RootStyle = styled(`div`)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom center",
  backgroundSize: "100%",
  display: "flex",
  minHeight: "100%",
  overflow: "auto",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "space-evenly",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
  },
  background:
    "linear-gradient(0deg, rgba(227,242,254,0.07) 0%, #D8ECFB 100%, #D8ECFB 100%);",
}));

export const OutletPanel = styled(`div`)(({ theme }) => ({
  minHeight: "100%",
  overflow: "auto",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
    width: 425,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    // alignItems: "end",
    width: "100vw",
  },
}));
