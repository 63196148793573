import { useEffect } from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { VITE_SITE_TITLE } from "../../config";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useLocation } from "react-router-dom";
import { Navigation } from ".";

function Page(props: any) {
  const { view: Component, title, anon, ...rest } = props;
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const location = useLocation();
  useEffect(() => {
    if (anon === false && !isLoggedIn) {
      Navigation.go("/auth/login?after=" + location.pathname + location.search);
    }
  }, [anon, isLoggedIn, location.pathname, location.search]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} | {VITE_SITE_TITLE}
        </title>
      </Helmet>
      <Component {...rest} />
    </>
  );
}

export function renderPageRoutes(pages: any) {
  if (!pages) {
    throw new Error("Missing pages: " + typeof pages);
  }
  return Object.keys(pages)
    .filter((pageName) => !!pages[pageName])
    .map((pageName) => {
      const { path, ...rest } = pages[pageName];
      if (!rest.title) {
        throw new Error("Page missing title");
      }
      return (
        <Route
          {...rest}
          key={path}
          // exact={pathExact}
          path={path}
          element={<Page {...rest} />}
        />
      );
    });
}
