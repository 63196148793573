import React from "react";
import { Box, Typography, TextField, Button, Link } from "@mui/material";
import { Form, useFormik, yup, RouterLink } from "../../lib";
import {
  ContainerStyle,
  FormStyle,
  TextFlexStyle,
  CenterElementStyle,
  ButtonStyle,
} from "./Auth.styles";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, authActions } from "../../state";
import { Checkbox } from "formik-mui";
import { Field } from "formik";

const validationSchema = yup.object({
  mfaCode: yup.string().required("Multifactor authentication code is required"),
});

export const MultifactorAuthPage = React.memo(
  /**
   *
   */
  function MultifactorAuthPage() {
    const dispatch = useDispatch<AppThunkDispatch>();
    const frm = useFormik({
      initialValues: { mfaCode: "", rememberMe: false },
      validationSchema,
      async onSubmit(values) {
        dispatch(authActions.multiFactorAuth(values));
      },
    });
    return (
      <ContainerStyle>
        <Box>
          <Typography variant="h3" gutterBottom>
            Multifactor Authentication
          </Typography>

          <Typography variant="body1" gutterBottom>
            Enter the multifactor authentication code that was sent to your
            device. If you have not set up a device, reach out to the office
          </Typography>

          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              dispatch(authActions.resendMfaCode("email"));
            }}
            fullWidth
          >
            Use email verification instead
          </Button>

          <FormStyle>
            <Form form={frm}>
              <TextFlexStyle>
                <TextField
                  autoFocus
                  fullWidth
                  type={"string"}
                  id="mfaCode"
                  name="mfaCode"
                  label="Multifactor authentication code"
                  value={frm.values.mfaCode || ""}
                  onChange={frm.handleChange}
                  error={frm.touched.mfaCode && Boolean(frm.errors.mfaCode)}
                  helperText={frm.touched.mfaCode && frm.errors.mfaCode}
                  autoComplete="off"
                />
              </TextFlexStyle>
              <Typography>
                <Field component={Checkbox} type="checkbox" name="rememberMe" />
                Remember me for 7 days
              </Typography>
              <ButtonStyle
                variant="contained"
                type="submit"
                disabled={frm.isSubmitting}
                fullWidth
              >
                Verify Code
              </ButtonStyle>
              <ButtonStyle
                variant="outlined"
                type="button"
                onClick={() => {
                  dispatch(authActions.resendMfaCode());
                }}
                fullWidth
              >
                Resend Code
              </ButtonStyle>
              <CenterElementStyle>
                <Link component={RouterLink} to={`/auth/login`}>
                  <Typography variant="body2"> Back to login </Typography>
                </Link>
              </CenterElementStyle>
            </Form>
          </FormStyle>
        </Box>
      </ContainerStyle>
    );
  },
);
