import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Box, Toolbar, Paper, Typography } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import { drawerWidth } from "./MiniDrawer";
import { useOutlet } from "react-router-dom";
import logo from "../../assets/svg/logo.svg";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar({ noDrawer }: { noDrawer?: boolean }) {
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const [currentDrawerWidth, setCurrentDrawerWidth] = useState(drawerWidth);
  const outlet = useOutlet();
  const pageTitle = outlet?.props.children.props.title;

  React.useEffect(() => {
    if (menuOpen) {
      setCurrentDrawerWidth(drawerWidth);
    } else {
      setCurrentDrawerWidth(73);
    }
  }, [menuOpen]);

  return (
    <PaperBar
      elevation={0}
      position="fixed"
      sx={{
        width: 1,
        pr: `${currentDrawerWidth}px`,
        backgroundColor: "rgba(0,0,0,0.03)",
      }}
    >
      <Toolbar>
        {(!menuOpen || noDrawer) && (
          <Box sx={{ position: "absolute", left: "50%" }}>
            <img src={logo} alt="instaraise logo" />
          </Box>
        )}
        <Typography sx={{ ml: 4, mt: 2 }} variant="h4" noWrap component="h1">
          {pageTitle}
        </Typography>
      </Toolbar>
    </PaperBar>
  );
}
