import { Card } from "./Card";
import AdminPages from "../../pages/admin";
import { Navigation } from "../../lib";
import { GetCardsApiResponse } from "../../state/rtk-query/state/admin";

export const DashboardCard = (data: GetCardsApiResponse[number]) => {
  return (
    <Card
      key={data.id}
      {...data}
      action={{
        title: "Select Card",
        onClick: () =>
          Navigation.go(
            AdminPages.card.path.replace(":id", data.id.toString()),
          ),
      }}
    />
  );
};
