import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface MicrosoftData {
  "@odata.context": string;
  value: any[];
}

export interface MicrosoftState {
  microsoftSharePointData: MicrosoftData[];
  hasMicrosoftAccessToken: boolean;
  outlookCalendarEvents: MicrosoftData[];
  outlookCalendarURL: string;
}
const initialState: MicrosoftState = {
  microsoftSharePointData: [],
  hasMicrosoftAccessToken: false,
  outlookCalendarEvents: [],
  outlookCalendarURL: "",
};
export const microsoft = {
  ...createSlice({
    name: "microsoft",
    initialState,
    reducers: {
      setMicrosoftSharePointData(
        state,
        { payload }: PayloadAction<MicrosoftData[]>,
      ) {
        state.microsoftSharePointData = payload;
      },
      setMicrosoftAccessToken(state, { payload }: PayloadAction<boolean>) {
        state.hasMicrosoftAccessToken = payload;
      },
      setOutlookCalendarEvents(
        state,
        { payload }: PayloadAction<MicrosoftData[]>,
      ) {
        state.outlookCalendarEvents = payload;
      },
      setOutlookCalendarURL(state, { payload }: PayloadAction<string>) {
        state.outlookCalendarURL = payload;
      },
    },
  }),
  persist: false,
};
