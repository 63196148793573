import { styled } from "@mui/material/styles";

export const DrawerHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  position: "fixed",
  zIndex: 1,
  right: 0,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  width: "100%",
  padding: 16,
  background: `linear-gradient(to bottom,#ffffff 80%, rgba(255, 255, 255, 0.71) 81.97%, rgba(255, 255, 255, 0) 100%);`,
}));
