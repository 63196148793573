import { styled } from "@mui/material";

export const RootStyle = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  backgroundColor: theme.palette.secondary.dark,
  textAlign: "center",
  ".paper": {
    backgroundColor: theme.palette.secondary.dark,
    paddingTop: "156px",
    objectFit: "cover",
    paddingBottom: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ".something-went-wrong": {
    height: "56px",
    color: theme.palette.primary.light,
    fontFamily: "Oxygen",
    fontSize: "48px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "56px",
  },
  ".were-not-sure-exact": {
    height: "28px",
    width: "626px",
    color: theme.palette.primary.light,
    fontFamily: "Oxygen",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    lineHeight: "28px",
    marginTop: "-10px",
  },
}));
