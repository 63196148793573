import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
  List,
  ListSubheader,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";
import { useHash } from "../../lib";
import { TabContext, TabPanel } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";
import { OfferCard } from "../../components/cards/OfferCard";

const OffersPage = () => {
  const [hash, _setHash] = useHash();
  const scrollToHash = (elId: string) => {
    const el = document.getElementById(elId);
    if (!el) return;
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
      // block: "end",
      // inline: "nearest",
    });
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 400);
  };
  useEffect(() => {
    if (hash) {
      scrollToHash(decodeURIComponent(hash));
    }
  }, [hash]);
  return (
    <Container maxWidth="md" sx={{ width: "96%", margin: "auto" }}>
      <Grid container>
        <Grid xs={12}>
          <TabContext value="offers">
            <OffersTab />
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

type Counts = {
  [key: string]: number;
};

function CategoryTabs({ counts }: { counts: Counts }) {
  const [hash, setHash] = useHash();
  const { id, option } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const categories = useMemo(() => data?.categories || [], [data?.categories]);

  return (
    <>
      {categories.length > 1 && (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            bgcolor: "white",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            width: "100%",
          }}
        >
          <Tabs
            variant="scrollable"
            onChange={(_e, elId) => {
              setHash(elId);
            }}
            value={decodeURIComponent(hash) || categories[0].id + ""}
          >
            {categories.map((item, i) => (
              <Tab
                key={i + "-" + option}
                label={item.name + " (" + (counts[item.id] || 0) + ")"}
                value={item.id + ""}
              />
            ))}
          </Tabs>
          <Divider sx={{ w: "100%", borderColor: "divider" }} />
        </Box>
      )}
    </>
  );
}

type Offer = {
  categoryId: number;
  category_name: string;
  offers: {
    id: number;
    offer_name: string;
    custom_expiration: null | string;
    number_of_uses: number | null;
    restrictions_text: null | string;
    estimated_value_per_use: string;
    redemption_method: "Manual" | "Code" | "Qr code" | "Barcode";
    redemption_code: null | string;
    used_count: number;
    /** Brand/merchant information */
    merchantId: number;
    logo: null | string;
    display_name: string;
  }[];
}[];
function OffersTab() {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [onlyAvailable, _setOnlyAvailable] = useState(true);
  const offers = useMemo(() => {
    if (!data?.categorizedMerchants) return [];
    const o: Offer = data?.categorizedMerchants.reduce((acc: Offer, curr) => {
      const offers: Offer[number]["offers"] = curr.merchants.reduce(
        (acc: Offer[number]["offers"], curr) => [
          ...acc,
          ...curr.offers
            .filter((o) => {
              if (onlyAvailable) {
                const expired =
                  new Date(o.custom_expiration || data!.expires_on) <
                  new Date();
                const usedUp =
                  !!o.number_of_uses && o.used_count >= o.number_of_uses;
                return !expired && !usedUp;
              }
              return true;
            })
            .map((o) => ({
              ...o,
              merchantId: curr.merchantId,
              logo: curr.logo,
              display_name: curr.display_name,
            })),
        ],
        [],
      );
      const categorizedOffers = {
        categoryId: curr.categoryId,
        category_name: curr.category_name,
        offers,
      };
      return [...acc, categorizedOffers];
    }, []);
    return o;
  }, [data?.categorizedMerchants, onlyAvailable]);
  const categoryCounts = useMemo(() => {
    if (!offers) return {};
    return offers.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.categoryId]: curr.offers.length,
      };
    }, {});
  }, [offers]);
  return (
    <TabPanel sx={{ p: 0 }} value="offers">
      <CategoryTabs counts={categoryCounts} />
      <Box
        sx={{
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          bgcolor: "white",
          p: 1,
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {offers.map((item) => (
          <List
            key={item.categoryId}
            id={item.categoryId + ""}
            subheader={
              <ListSubheader
                component="a"
                href={`#${item.categoryId}`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography fontWeight="bold" component="span" marginRight={1}>
                  {item.category_name}
                </Typography>
                ({item.offers.length} offer{item.offers.length > 1 ? "s" : ""})
              </ListSubheader>
            }
            sx={{ scrollMarginTop: 54, width: 1 }}
          >
            {item.offers.map((offer) => (
              <OfferCard
                key={offer.id}
                expires_on={data?.expires_on!}
                {...offer}
              />
            ))}
          </List>
        ))}
      </Box>
    </TabPanel>
  );
}

export default OffersPage;
