import { useEffect } from "react";
import { Typography } from "@mui/material";
import { Navigation } from "../../lib";
import { logout, authSelectors } from "../../state";
import { useSelector } from "react-redux";
import { MainPages } from "../main";

export const LogoutPage = () => {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        logout();
      }
      Navigation.reload(MainPages.home.path);
    })();
  }, [logout, isLoggedIn]);

  return <Typography variant="h2">Logging out...</Typography>;
};
