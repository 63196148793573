import { PatternFormat, PatternFormatProps } from "react-number-format";
import { Ref, forwardRef, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PatternFormatCustom = forwardRef(
  (props: PatternFormatProps & CustomProps, _ref: Ref<HTMLInputElement>) => {
    const { onChange, ...other } = props;
    return (
      <PatternFormat
        {...other}
        // ref={ref}
        type="tel"
        format="+1 (###) ###-####"
        // allowEmptyFormatting // TODO allowEmptyFormatting will show +1 (___) ___-____ instead of empty input
        mask="_"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

export const PhoneField = () => {
  const [field, meta, helpers] = useField("phone");
  const { onChange: _, onBlur, ...fieldRest } = field; // discard onChange because NumberFormat change is handled properly via onValueChange

  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    //WARNING: this causes validation to run but makes form less performant
    helpers.setValue(event.target.value);
  };
  const handleBlur = (event: React.FocusEvent<any, Element>) => {
    onBlur(event);
    helpers.setValue(value);
  };
  useEffect(() => {
    if (meta.initialValue) {
      setValue(meta.initialValue);
    }
  }, [meta.initialValue]);

  return (
    <TextField
      {...fieldRest}
      fullWidth
      label="Phone number"
      value={value}
      onChange={handleChange}
      name="phone"
      type="tel"
      id="formatted-phone-input"
      InputProps={{
        inputComponent: PatternFormatCustom as any,
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === "mui-auto-fill" && helpers.setValue(value, true);
        },
      }}
      InputLabelProps={{ shrink: true }}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key == "Enter" || e.key == "Tab") {
          helpers.setValue(value, true);
        }
      }}
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};
