import * as Sentry from "@sentry/react";

import {
  __DEV__,
  NODE_ENV,
  VITE_ERRORS_DEV,
  VITE_ERRORS_DSN,
  VITE_VERSION,
} from "../../config";
import { ErrorPage } from "../../pages/main/ErrorPage";

const usingDevConfig = __DEV__ && !VITE_ERRORS_DEV;

const devConfig = usingDevConfig
  ? {
      beforeSend() {
        // Do not send errors in development.
        return null;
      },
    }
  : {};

if (__DEV__ && !usingDevConfig) {
  console.warn("Reporting errors to https://sentry.io during development.");
}

/** See https://docs.sentry.io/learn/configuration */
export function init() {
  Sentry.init({
    environment: NODE_ENV,
    release: VITE_VERSION,
    dsn: VITE_ERRORS_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.05, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/api\.instaraise\.com/,
      /^https:\/\/devapi\.instaraise\.net/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ...devConfig,
  });
}

export function ErrorSentryFallBack() {
  return <ErrorPage />;
}
