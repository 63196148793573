import { api } from "../api";
export const addTagTypes = [
  "users",
  "multi_factor_auth",
  "google",
  "calendar",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      requestOtpCode: build.mutation<
        RequestOtpCodeApiResponse,
        RequestOtpCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/discount_card_users/request_otp_code`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      requestOtpWithCode: build.mutation<
        RequestOtpCodeApiResponse,
        RequestOtpWithCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/discount_cards/activate_customer_card`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authLogin: build.mutation<AuthLoginApiResponse, AuthLoginApiArg>({
        query: (queryArg) => ({
          url: `/discount_card_users/otp_login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authRefreshToken: build.query<
        AuthRefreshTokenApiResponse,
        AuthRefreshTokenApiArg
      >({
        query: () => ({ url: `/auth/refresh-token` }),
        providesTags: ["users"],
      }),
      authLogout: build.query<AuthLogoutApiResponse, AuthLogoutApiArg>({
        query: () => ({ url: `/logout` }),
        providesTags: ["users"],
      }),
      authSocialLogin: build.mutation<
        AuthSocialLoginApiResponse,
        AuthSocialLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/social-login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authMultiFactorAuth: build.mutation<
        AuthMultiFactorAuthApiResponse,
        AuthMultiFactorAuthApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/multi-factor-auth`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["multi_factor_auth"],
      }),
      getGoogleOauthRedirect: build.query<
        GetGoogleOauthRedirectApiResponse,
        GetGoogleOauthRedirectApiArg
      >({
        query: () => ({ url: `/google/oauth-redirect` }),
        providesTags: ["google"],
      }),
      getGoogleAuthStatus: build.query<
        GetGoogleAuthStatusApiResponse,
        GetGoogleAuthStatusApiArg
      >({
        query: () => ({ url: `/google/oauth-status` }),
        providesTags: ["google", "calendar"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };

export type RequestOtpCodeApiResponse = /** status 200 */ {
  success: boolean;
  message?: string | null;
};

export type RequestOtpCodeApiArg = {
  body: {
    phone: string;
  };
};

export type RequestOtpWithCodeApiArg = {
  body: {
    phone: string;
    activation_code: string;
  };
};
export type AuthLoginApiResponse = /** status 200 jwt permissions token */ {
  token: string;
  refreshToken?: string;
  expiration?: string;
  user?: {
    id?: number;
    email?: string;
    phone?: string | null;
    first_name?: string;
    last_name?: string;
    login_attempts?: number;
    last_login?: string;
    last_failed_login?: string | null;
    mfa_required?: boolean;
    roles?: any;
  };
};

export type AuthLoginApiArg = {
  /** user login info */
  body: {
    phone: string;
    otp_code: string;
  };
};
export type AuthRefreshTokenApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      login_attempts?: number;
      last_login?: string;
      last_failed_login?: string | null;
      mfa_required?: boolean;
      roles?: any;
    };
  };
export type AuthRefreshTokenApiArg = void;
export type AuthLogoutApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthLogoutApiArg = void;
export type AuthSocialLoginApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      login_attempts?: number;
      last_login?: string;
      last_failed_login?: string | null;
      mfa_required?: boolean;
      roles?: any;
    };
  };
export type AuthSocialLoginApiArg = {
  /** user social login info */
  body: {
    sign_in_type: "google" | "microsoft";
    credential: string;
    [key: string]: any;
  };
};
export type AuthMultiFactorAuthApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id?: number;
      email?: string;
      phone?: string | null;
      first_name?: string;
      last_name?: string;
      login_attempts?: number;
      last_login?: string;
      last_failed_login?: string | null;
      mfa_required?: boolean;
      roles?: any;
    };
  };
export type AuthMultiFactorAuthApiArg = {
  /** mfa code verify */
  body: {
    mfaCode: string;
    rememberMe: boolean;
  };
};
export type AuthResendMfaCodeApiResponse =
  /** status 204 An empty response */ undefined;
export type AuthResendMfaCodeApiArg = {
  /** defines the type */
  type?: "email" | "sms";
};
export type GetGoogleOauthRedirectApiResponse =
  /** status 200 Oauth redirect url */ {
    oauthRedirectUrl: string;
  };
export type GetGoogleOauthRedirectApiArg = void;
export type GetGoogleAuthStatusApiResponse =
  /** status 200 Oath Status check */ {
    hasAccessToken: boolean;
  };
export type GetGoogleAuthStatusApiArg = void;
export const {
  /** requestOtpCode */
  useRequestOtpCodeMutation,
  /** authLogin */
  useAuthLoginMutation,
  /** authRefreshToken */
  useAuthRefreshTokenQuery,
  /** authRefreshToken */
  useLazyAuthRefreshTokenQuery,
  /** authLogout */
  useAuthLogoutQuery,
  /** authLogout */
  useLazyAuthLogoutQuery,
  /** authSocialLogin */
  useAuthSocialLoginMutation,
  /** authMultiFactorAuth */
  useAuthMultiFactorAuthMutation,
  /** getGoogleOauthRedirect */
  useGetGoogleOauthRedirectQuery,
  /** getGoogleOauthRedirect */
  useLazyGetGoogleOauthRedirectQuery,
  /** getGoogleAuthStatus */
  useGetGoogleAuthStatusQuery,
  /** getGoogleAuthStatus */
  useLazyGetGoogleAuthStatusQuery,
  /** requestOtpWithCode */
  useRequestOtpWithCodeMutation,
} = injectedRtkApi;
