import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { authActions, persistor, store } from "./state";

// Example to use service worker / progressive web app (PWA):
// import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorkerConfig";

import { defaultTheme } from "./themes";
import "./assets/fonts/graphik.css";
import "./assets/css/styles.css";

import { App } from "./App";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { VITE_MUI_LICENSE } from "./config";
import { PersistGate } from "redux-persist/es/integration/react";
import { ErrorSentryFallBack, init } from "./components/errors/ErrorSentry";
import * as Sentry from "@sentry/react";

init();
// NOTE: The `store` already comes preloaded slices that where persisted.

/**
 * Startup function.
 */
LicenseInfo.setLicenseKey(VITE_MUI_LICENSE!);

function main() {
  const dispatch: any = store.dispatch;
  dispatch(authActions.load({}));
  render();
  registerServiceWorker();
}
/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */
function registerServiceWorker() {
  // Example to implement register service worker:
  // serviceWorker.register(serviceWorkerConfig);
}

function render() {
  // TODO: Add <ErrorSentry> as root.
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);
  root.render(
    <Sentry.ErrorBoundary fallback={<ErrorSentryFallBack />} showDialog>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <CssBaseline />
              <App />
            </PersistGate>
          </ReduxProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>,
  );
}

main();
