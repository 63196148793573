import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  APIProvider,
  AdvancedMarker,
  InfoWindow,
  Map as GoogleMap,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { VITE_MAPS_API_KEY } from "../../config";
import { useParams } from "react-router-dom";
import { Navigation, RouterLink, useHash } from "../../lib";
import { TabContext, TabPanel } from "@mui/lab";
import { useMobile } from "../../themes";
import { useCallback, useEffect, useMemo, useState } from "react";
import AdminPages from ".";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";
import { Close, LocalPhoneOutlined, PublicOutlined } from "@mui/icons-material";
import { OfferCardLink } from "../../components/cards/OfferCard";

export const Header = () => {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const isExpired = useMemo(() => {
    if (data?.expires_on) return new Date(data?.expires_on) < new Date();
    return false;
  }, [data?.expires_on]);

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      {!!data?.org_logo && (
        <img
          src={data.org_logo}
          style={{
            width: 32,
            height: 32,
            borderRadius: "50%",
            marginRight: 12,
            marginTop: 4,
          }}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
        <Typography
          fontWeight="medium"
          variant="body1"
          component="div"
          color="text.secondary"
        >
          {data?.card_name}
        </Typography>
        <Typography
          variant="caption"
          color={isExpired ? "error.main" : "text.secondary"}
        >
          {isExpired ? "Expired" : "Expires"}:{" "}
          {data?.expires_on
            ? new Date(data?.expires_on).toLocaleDateString()
            : null}
        </Typography>
      </Box>
    </Box>
  );
};
const CardPageOptions = () => {
  const { id, option } = useParams();
  const [hash, _setHash] = useHash();
  const scrollToHash = (elId: string) => {
    const el = document.getElementById(elId);
    if (!el) return;
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 400);
  };
  useEffect(() => {
    if (hash) {
      scrollToHash(decodeURIComponent(hash));
    }
  }, [hash]);
  const isMobile = useMobile();
  return (
    <Container maxWidth="lg" sx={{ width: "96%", margin: "auto" }}>
      <Grid container>
        <Grid xs={12}>
          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: "background.paper",
              p: 2,
              mb: 2,
            }}
          >
            <Header />
          </Box>
        </Grid>
        <Grid xs={12}>
          <TabContext value={option!}>
            <Box
              sx={{
                position: "sticky",
                top: isMobile ? 57 : 0,
                bgcolor: "inherit",
                zIndex: 1000,
              }}
            >
              <ToggleButtonGroup
                fullWidth
                size="small"
                value={option}
                color="primary"
                exclusive
                sx={{ display: "flex", mb: 2, bgcolor: "white" }}
                onChange={(_e, value) => {
                  if (value) Navigation.go(`/admin/cards/${id}/${value}`);
                }}
              >
                <ToggleButton
                  value="brands"
                  sx={{ ml: "auto", borderColor: "primary.main" }}
                >
                  <Typography fontWeight="bold" color="primary.main">
                    Brands
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  value="offers"
                  sx={{ borderColor: "primary.main" }}
                >
                  <Typography fontWeight="bold" color="primary.main">
                    Offers
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  value="map"
                  sx={{ mr: "auto", borderColor: "primary.main" }}
                >
                  <Typography fontWeight="bold" color="primary.main">
                    Map
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <OffersTab />
            <BrandsTab />
            <MapTab />
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

const HeaderSection = ({
  title,
  setOnlyAvailable,
  onlyAvailable,
}: {
  title: string;
  setOnlyAvailable: (checked: boolean) => void;
  onlyAvailable: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        mt: 2,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <FormControlLabel
        control={<Checkbox checked={onlyAvailable} />}
        onChange={(_e, checked) => setOnlyAvailable(checked)}
        label="Only show available"
      />
    </Box>
  );
};

type Counts = {
  [key: string]: number;
};

function CategoryTabs({ counts }: { counts: Counts }) {
  const isMobile = useMobile();
  const [hash, setHash] = useHash();
  const { id, option } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const categories = useMemo(() => data?.categories || [], [data?.categories]);

  return (
    <>
      {categories.length > 1 && (
        <Box
          sx={{
            position: "sticky",
            top: isMobile ? 97 : 40,
            zIndex: 1000,
            bgcolor: "white",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            width: "100%",
          }}
        >
          <Tabs
            variant="scrollable"
            onChange={(_e, elId) => {
              setHash(elId);
            }}
            value={decodeURIComponent(hash) || categories[0].id + ""}
          >
            {categories.map((item, i) => (
              <Tab
                key={i + "-" + option}
                label={item.name + " (" + (counts[item.id] || 0) + ")"}
                value={item.id + ""}
              />
            ))}
          </Tabs>
          <Divider sx={{ w: "100%", borderColor: "divider" }} />
        </Box>
      )}
    </>
  );
}

function BrandsTab() {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [onlyAvailable, setOnlyAvailable] = useState(true);
  const isMobile = useMobile();
  const brands = useMemo(() => {
    return (
      data?.categorizedMerchants.filter((arg) => {
        return !!arg.merchants.find((m) => {
          if (!onlyAvailable) {
            return true;
          }
          return !!m.offers.find((o) => {
            const expired =
              new Date(o.custom_expiration || data!.expires_on) < new Date();
            const usedUp =
              !!o.number_of_uses && o.used_count >= o.number_of_uses;

            return !expired && !usedUp;
          });
        });
      }) || []
    );
  }, [data?.categorizedMerchants, onlyAvailable]);
  const categoryCounts = useMemo(() => {
    if (!brands) return {};
    return brands.reduce((acc, curr) => {
      return { ...acc, [curr.categoryId]: curr.merchants.length };
    }, {});
  }, [brands]);

  return (
    <TabPanel sx={{ p: 0 }} value="brands">
      <HeaderSection
        title="All brands"
        setOnlyAvailable={setOnlyAvailable}
        onlyAvailable={onlyAvailable}
      />
      <CategoryTabs counts={categoryCounts} />
      <Box
        sx={{
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          bgcolor: "white",
          p: 1,
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {brands.map((item) => (
          <List
            key={item.categoryId}
            id={item.categoryId + ""}
            subheader={
              <ListSubheader
                component="a"
                href={`#${item.categoryId}`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography fontWeight="bold" component="span" marginRight={1}>
                  {item.category_name}
                </Typography>
                ({item.merchants.length} brands)
              </ListSubheader>
            }
            sx={{ scrollMarginTop: isMobile ? 140 : 94, width: 1 }}
          >
            {item.merchants.map((merchant) => (
              <Link
                key={merchant.merchantId}
                component={RouterLink}
                to={AdminPages.brand.path
                  .replace(":id", id!)
                  .replace(":brandId", merchant.merchantId.toString())}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                  }}
                >
                  {merchant.logo ? (
                    <ListItemIcon
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        overflow: "hidden",
                        bgcolor: "background.paper",
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        src={merchant.logo}
                        alt={merchant.display_name}
                        style={{
                          width: "64px",
                          height: "64px",
                          objectFit: "contain",
                        }}
                      />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText
                    sx={{ p: 0, m: 0, mt: -1 }}
                    primary={
                      <Typography fontWeight="bold">
                        {merchant.display_name}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="span"
                        >
                          {merchant.offers.length +
                            " offer" +
                            (merchant.offers.length > 1 ? "s" : "")}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="span"
                          sx={{ mx: 1 }}
                        >
                          &bull;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="span"
                        >
                          {merchant.locations.length} Locations
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        ))}
      </Box>
    </TabPanel>
  );
}
type Offer = {
  categoryId: number;
  category_name: string;
  offers: {
    id: number;
    offer_name: string;
    custom_expiration: null | string;
    number_of_uses: number | null;
    restrictions_text: null | string;
    estimated_value_per_use: string;
    redemption_method: "Manual" | "Code" | "Qr code" | "Barcode";
    redemption_code: null | string;
    used_count: number;
    /** Brand/merchant information */
    merchantId: number;
    logo: null | string;
    display_name: string;
  }[];
}[];
type Location = {
  id: number;
  location_name: null | string;
  address: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  website?: string;
  phone?: string;
  /** Brand/merchant information */
  merchantId: number;
  logo: null | string;
  display_name: string;
}[];
function OffersTab() {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [onlyAvailable, setOnlyAvailable] = useState(true);
  const offers = useMemo(() => {
    if (!data?.categorizedMerchants) return [];
    const o: Offer = data?.categorizedMerchants.reduce((acc: Offer, curr) => {
      const offers: Offer[number]["offers"] = curr.merchants.reduce(
        (acc: Offer[number]["offers"], curr) => [
          ...acc,
          ...curr.offers
            .filter((o) => {
              if (onlyAvailable) {
                const expired =
                  new Date(o.custom_expiration || data!.expires_on) <
                  new Date();
                const usedUp =
                  !!o.number_of_uses && o.used_count >= o.number_of_uses;
                return !expired && !usedUp;
              }
              return true;
            })
            .map((o) => ({
              ...o,
              merchantId: curr.merchantId,
              logo: curr.logo,
              display_name: curr.display_name,
            })),
        ],
        [],
      );
      const categorizedOffers = {
        categoryId: curr.categoryId,
        category_name: curr.category_name,
        offers,
      };
      return [...acc, categorizedOffers];
    }, []);
    return o;
  }, [data?.categorizedMerchants, onlyAvailable]);
  const categoryCounts = useMemo(() => {
    if (!offers) return {};
    return offers.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.categoryId]: curr.offers.length,
      };
    }, {});
  }, [offers]);
  const isMobile = useMobile();
  return (
    <TabPanel sx={{ p: 0 }} value="offers">
      <HeaderSection
        title="All offers"
        setOnlyAvailable={setOnlyAvailable}
        onlyAvailable={onlyAvailable}
      />
      <CategoryTabs counts={categoryCounts} />
      <Box
        sx={{
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          bgcolor: "white",
          p: 1,
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {offers.map((item) => (
          <List
            key={item.categoryId}
            id={item.categoryId + ""}
            subheader={
              <ListSubheader
                component="a"
                href={`#${item.categoryId}`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography fontWeight="bold" component="span" marginRight={1}>
                  {item.category_name}
                </Typography>
                ({item.offers.length} offer{item.offers.length > 1 ? "s" : ""})
              </ListSubheader>
            }
            sx={{ scrollMarginTop: isMobile ? 140 : 94, width: 1 }}
          >
            {item.offers.map((offer) => (
              <OfferCardLink expires_on={data?.expires_on!} {...offer} />
            ))}
          </List>
        ))}
      </Box>
    </TabPanel>
  );
}

const MarkerWithInfoWindow = (
  props: Location[number] & {
    position: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;
  },
) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(() => {
    if (props.display_name) setInfoWindowShown((isShown) => !isShown);
  }, []);
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <AdvancedMarker
        position={props.position}
        ref={markerRef}
        onClick={handleMarkerClick}
      />
      {infoWindowShown ? (
        <InfoWindow
          anchor={marker}
          className="infowindow"
          onClose={handleClose}
          headerDisabled
          //@ts-ignore
          //TODO: update this when library updates see https://github.com/visgl/react-google-maps/issues/378
          // headerContent={<h3>InfoWindow Header Content</h3>}
          // headerContent={
          //   <p style={{ fontWeight: "bold" }}>{props.display_name}</p>
          // } // props.display_name}
          style={{
            paddingRight: 0,
          }}
        >
          <Box
            sx={{
              pb: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className="address"
              sx={{
                fontSize: "14px",
                lineHeight: "16px",
                fontWeight: "bold",
                color: "#212121",
              }}
            >
              {props.display_name}
            </Typography>
            <IconButton sx={{ ml: 2 }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography
            className="address"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              color: "#757575",
              margin: "0",
            }}
          >
            {props.address}
          </Typography>
          <Typography
            className="address"
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              color: "#757575",
              margin: "0",
            }}
          >
            {props.city}, {props.state} {props.zip}
          </Typography>
          <Box display="flex" gap={2} marginTop={1}>
            {props.website ? (
              <Typography
                className="address"
                style={{
                  fontSize: "13px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  textDecoration: "none",
                  flexShrink: 0,
                }}
                component="a"
                href={
                  props.website.startsWith("https://")
                    ? props.website
                    : "https://" + props.website
                }
                target="_blank"
                color="primary"
              >
                <PublicOutlined fontSize="small" />{" "}
                {props.phone && props.website ? "Visit" : "Website"}
              </Typography>
            ) : null}
            {props.phone ? (
              <Typography
                className="address"
                style={{
                  fontSize: "13px",
                  lineHeight: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  textDecoration: "none",
                  flexShrink: 0,
                }}
                component="a"
                href={"tel:" + props.phone}
                color="primary"
              >
                <LocalPhoneOutlined fontSize="small" />
                Call
              </Typography>
            ) : null}
          </Box>
        </InfoWindow>
      ) : null}
    </>
  );
  // `markerRef` and `marker` are needed to establish the connection between
  // the marker and infowindow (if you're using the Marker component, you
  // can use the `useMarkerRef` hook instead).
  // const [markerRef, marker] = useAdvancedMarkerRef();

  // if the maps api closes the infowindow, we have to synchronize our state
};
function MapTab() {
  const { id } = useParams();
  const { data, isSuccess } = useGetCardQuery({ id: Number(id) });
  const [onlyAvailable, setOnlyAvailable] = useState(true);
  const locations = useMemo(() => {
    if (!data?.categorizedMerchants) return [];
    const o: Location = data?.categorizedMerchants.reduce(
      (acc: Location, curr) => {
        const locations: Location = curr.merchants
          .filter((m) => {
            if (!onlyAvailable) {
              return true;
            }
            return !!m.offers.find((o) => {
              const expired =
                new Date(o.custom_expiration || data!.expires_on) < new Date();
              const usedUp =
                !!o.number_of_uses && o.used_count >= o.number_of_uses;
              return !expired && !usedUp;
            });
          })
          .reduce(
            (acc: Location, curr) => [
              ...acc,
              ...curr.locations.map((o) => ({
                ...o,
                merchantId: curr.merchantId,
                logo: curr.logo,
                display_name: curr.display_name,
              })),
            ],
            [],
          );
        return [...acc, ...locations];
      },
      [],
    );
    return o;
  }, [data?.categorizedMerchants, onlyAvailable]);
  const defaultBounds = useMemo(() => {
    if (!locations) return undefined;
    const lats = locations.map((o) => o.latitude);
    const lngs = locations.map((o) => o.longitude);
    const east = Math.max(...lngs) + 0.001;
    const west = Math.min(...lngs) - 0.001;
    const north = Math.max(...lats) + 0.001;
    const south = Math.min(...lats) - 0.001;
    return { east, west, north, south };
  }, [locations]);

  return (
    <TabPanel sx={{ p: 0 }} value="map">
      <APIProvider apiKey={VITE_MAPS_API_KEY} version="beta">
        <HeaderSection
          title="All locations"
          setOnlyAvailable={setOnlyAvailable}
          onlyAvailable={onlyAvailable}
        />
        {isSuccess && locations && defaultBounds && (
          <GoogleMap
            className="MapStyles LargeMapStyles"
            mapId="f1014d1d78dc2a71"
            defaultBounds={defaultBounds}
          >
            {locations.map((o) => (
              <MarkerWithInfoWindow
                key={o.id}
                position={{ lat: o.latitude, lng: o.longitude }}
                {...o}
              />
            ))}
          </GoogleMap>
        )}
      </APIProvider>
    </TabPanel>
  );
}
export default CardPageOptions;
