import * as authApi from "./auth";
import * as googleApi from "./google";
import * as microsoftApi from "./microsoft";
import * as adminApi from "./admin";

export const actions = {
  ...authApi,
  ...googleApi,
  ...microsoftApi,
  ...adminApi,
};
