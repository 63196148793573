import List from "@mui/material/List";
import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { DeviceUnknown as DeviceUnknownIcon } from "@mui/icons-material";
import AdminPages from "../../pages/admin";
import {
  useNavigate,
  useLocation,
  To,
  useParams,
  matchPath,
} from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import { useMobile } from "../../themes";
import { uiActions, useDispatch } from "../../state";

export default function Menu() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const itemButtonClick = (
    to: To,
    initialParams?: { [key: string]: string },
  ) => {
    if (isMobile) {
      handleDrawerClose();
    }
    let _to = to;
    if (params) {
      for (const key of Object.keys(params)) {
        if (typeof _to === "string" && typeof params[key] === "string") {
          _to = _to.replace(`:${key}`, params[key] as string);
        }
      }
    }
    //if even after looping through available params, there is still a colon in the string, replace it with initialParams
    if ((_to as string).includes(":") && initialParams) {
      for (const [key, value] of Object.entries(initialParams)) {
        _to = (_to as string).replace(`:${key}`, value);
      }
    }
    navigate(_to);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <List>
            {Object.values(AdminPages)
              .filter((p) => Boolean(p.Icon))
              .map(
                ({
                  title,
                  Icon = DeviceUnknownIcon,
                  path,
                  childrenPaths,
                  initialParams,
                }) => (
                  <ListItemButton
                    onClick={() => itemButtonClick(path, initialParams)}
                    key={title}
                    selected={
                      !!matchPath(path, location.pathname) ||
                      !!childrenPaths?.find((p) =>
                        matchPath(p, location.pathname),
                      )
                    }
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItemButton>
                ),
              )}
          </List>
        </Box>
        <Box>
          <List>
            <ProfileMenu />
          </List>
        </Box>
      </Box>
    </>
  );
}
