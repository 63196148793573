import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Unstable_Grid2";
import { VITE_MAPS_API_KEY } from "../../config";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, useRef, useMemo } from "react";
import { GetCardApiResponse, actions } from "../../state/rtk-query/state/admin";
import ExpandMore from "@mui/icons-material/ExpandMore";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import { useMobile } from "../../themes";
import { Navigation } from "../../lib";
import AdminPages from ".";
import high_five from "../../assets/svg/high_five.svg";
import {
  CloseOutlined,
  LocalPhoneOutlined,
  PublicOutlined,
} from "@mui/icons-material";
import { DrawerHeader } from "../../components/drawer";
import { ThreeTwoImg } from "../../components/img/ThreeTwo";

const { useGetCardQuery, useRedeemOfferMutation } = actions;

const Timer = ({
  timeInSeconds = 300,
  onCompleteAction,
}: {
  timeInSeconds?: number;
  onCompleteAction: (arg?: any) => void;
}) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef<NodeJS.Timer | null>(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e: Date) => {
    const d = new Date();
    const total = e.getTime() - d.getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: Date) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds),
      );
      if (total <= 1000) {
        onCompleteAction();
      }
    }
  };
  const clearTimer = (e: Date) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("05:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    // This is where you need to adjust if
    // you intend to add more time
    deadline.setSeconds(deadline.getSeconds() + timeInSeconds);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
    return () => {
      clearInterval(Ref.current as NodeJS.Timer);
    };
  }, []);

  const timestamp = useMemo(() => {
    return new Date().toLocaleString();
  }, []);
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        sx={{ textAlign: "left", margin: "auto", width: 180 }}
        fontWeight={500}
        color="error.main"
      >
        Time remaining: {timer}
      </Typography>
      <Typography fontWeight={500} color="text.secondary">
        Timestamp: {timestamp}
      </Typography>
    </Box>
  );
};

const RedemptionMethod = (
  props: GetCardApiResponse["categorizedMerchants"][number]["merchants"][number]["offers"][number],
) => {
  if (!props.redemption_code) {
    return null;
  }
  switch (props.redemption_method) {
    case "Manual":
      return null;
    case "Code":
      return <Typography variant="h5">{props.redemption_code}</Typography>;
    case "Qr code":
      return (
        <>
          <QRCode size={96} value={props.redemption_code} />
          <Typography
            sx={{ m: "auto", textAlign: "center" }}
            fontSize={16}
            fontWeight={500}
          >
            {props.redemption_code}
          </Typography>
        </>
      );
    case "Barcode":
      return <Barcode value={props.redemption_code} />;
    default:
      return null;
  }
};

const HeaderSection = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        mt: 2,
        width: "96%",
        mr: "auto",
        ml: "auto",
      }}
    >
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

const defaultTcs = `Cannot be combined with any other coupons, discounts, offers, or
        promotions. Coupon has no cash value. Code must be entered at checkout.
        Offer valid on in-stock items only. Offer not valid on previous
        purchases. Company reserves the right to modify or cancel coupons at any
        time .`;

const BrandPage = () => {
  const { id, brandId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [showTCs, setShowTCs] = useState("");

  const category = data?.categorizedMerchants.find(({ merchants }) =>
    merchants.find(({ merchantId: _id }) => _id === Number(brandId)),
  );
  const brand = category?.merchants.find(
    ({ merchantId: _id }) => _id === Number(brandId),
  );

  return (
    <>
      <Container maxWidth="md">
        <Grid container>
          <Grid xs={12} sx={{ flexGrow: 1, mb: 2 }}>
            <Box
              sx={{
                width: "96%",
                margin: "auto",
                borderRadius: "9px",
                bgcolor: "background.paper",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                p: 2,
              }}
            >
              {brand?.logo ? (
                <img
                  src={brand.logo}
                  alt={brand?.display_name}
                  height={64}
                  style={{ marginBottom: 12 }}
                />
              ) : null}
              <Typography variant="h6">{brand?.display_name}</Typography>
              {(brand?.offers?.length && brand.offers.length > 1) ||
              (brand?.locations?.length && brand?.locations?.length > 1) ? (
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  {brand?.offers?.length && brand.offers.length > 1 ? (
                    <Typography variant="caption">
                      {brand.offers.length} Offers
                    </Typography>
                  ) : null}
                  {brand?.offers?.length &&
                  brand.offers.length > 1 &&
                  brand?.locations?.length &&
                  brand?.locations?.length > 1 ? (
                    <Typography variant="caption">&bull;</Typography>
                  ) : null}
                  {brand?.locations?.length && brand?.locations?.length > 1 ? (
                    <Typography variant="caption">
                      {brand.locations.length} Locations
                    </Typography>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid xs={12} sx={{ flexGrow: 1 }}>
            {brand?.offers.length && brand?.offers.length > 1 ? (
              <HeaderSection title="Offers" />
            ) : null}
            {brand?.offers.map((offer) => (
              <Card
                elevation={0}
                key={offer.id}
                sx={{ mb: 2, width: "96%", ml: "auto", mr: "auto" }}
              >
                <CardContent
                  sx={{
                    opacity:
                      !offer.number_of_uses ||
                      offer.number_of_uses - offer.used_count
                        ? 1
                        : 0.5,
                  }}
                >
                  <Typography variant="h6">{offer.offer_name}</Typography>
                  <Box display="flex" gap={1}>
                    {!offer.number_of_uses ||
                    offer.number_of_uses - offer.used_count ? (
                      <>
                        <Typography variant="body2" color="text.secondary">
                          {!offer.number_of_uses
                            ? "Unlimited"
                            : `${offer.number_of_uses - offer.used_count}/${
                                offer.number_of_uses
                              }`}{" "}
                          uses available
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          &bull;
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Expires{" "}
                          {new Date(
                            offer.custom_expiration || data!.expires_on,
                          ).toLocaleDateString()}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" color="error.main">
                        Redeemed {offer.used_count}/{offer.number_of_uses} times
                      </Typography>
                    )}
                  </Box>
                </CardContent>
                {!offer.number_of_uses ||
                offer.number_of_uses - offer.used_count ? (
                  <CardActions sx={{ flexDirection: "column", gap: 1, px: 2 }}>
                    <Tooltip
                      title={
                        data?.isPreviewMode
                          ? "Offer redemption disabled in preview mode"
                          : ""
                      }
                      enterTouchDelay={0}
                      style={{ zIndex: 1 }}
                      placement="top-start"
                      arrow
                    >
                      <Box sx={{ width: "100%" }}>
                        <Button
                          size="small"
                          fullWidth
                          variant="contained"
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            setSearchParams({ offer: offer.id.toString() });
                          }}
                          disabled={data?.isPreviewMode}
                        >
                          Redeem offer
                        </Button>
                      </Box>
                    </Tooltip>
                    <Button
                      size="small"
                      fullWidth
                      variant="text"
                      sx={{ textTransform: "none" }}
                      onClick={() =>
                        setShowTCs(offer.restrictions_text || defaultTcs)
                      }
                    >
                      Terms and conditions apply
                    </Button>
                  </CardActions>
                ) : null}
              </Card>
            ))}
          </Grid>
          <Grid xs={12} sx={{ flexGrow: 1 }}>
            {brand?.locations.length && brand?.locations.length > 1 ? (
              <HeaderSection title="Locations" />
            ) : null}
            <Box
              sx={{
                width: "96%",
                margin: "auto",
                borderRadius: "9px",
                bgcolor: "background.paper",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                px: 2,
                pt: 2,
              }}
            >
              {brand?.locations.map((l, i) => (
                <Box width={1} sx={{ mb: 2 }} display="flex" key={i} gap={2}>
                  {/** hack to overlay the embed to prevent scroll to zoom */}
                  <div className="Overlay"></div>
                  <Box className="TinyMapStyles" sx={{ opacity: 0.5 }}>
                    <iframe
                      width={300}
                      height={300}
                      style={{
                        border: "none",
                      }}
                      loading="lazy"
                      allowFullScreen={false}
                      referrerPolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=${VITE_MAPS_API_KEY}&q=${l.latitude},${l.longitude}&center=${l.latitude},${l.longitude}&maptype=roadmap&zoom=12`}
                    ></iframe>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{ mb: 0.5 }}
                    >
                      {l.location_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {l.address}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {l.city}, {l.state} {l.zip}
                    </Typography>
                    {l.phone || l.website ? (
                      <Box display="flex" gap={2} marginTop={1}>
                        {l.website ? (
                          <Typography
                            className="address"
                            style={{
                              fontSize: "13px",
                              lineHeight: "16px",
                              display: "flex",
                              alignItems: "center",
                              gap: 3,
                              textDecoration: "none",
                              flexShrink: 0,
                            }}
                            component="a"
                            href={
                              l.website.startsWith("https://")
                                ? l.website
                                : "https://" + l.website
                            }
                            target="_blank"
                            color="primary.main"
                          >
                            <PublicOutlined fontSize="small" color="inherit" />{" "}
                            {l.phone && l.website ? "Visit" : "Website"}
                          </Typography>
                        ) : null}
                        {l.phone ? (
                          <Typography
                            className="address"
                            style={{
                              fontSize: "13px",
                              lineHeight: "16px",
                              display: "flex",
                              alignItems: "center",
                              gap: 3,
                              textDecoration: "none",
                              flexShrink: 0,
                            }}
                            component="a"
                            href={"tel:" + l.phone}
                            color="primary.main"
                          >
                            <LocalPhoneOutlined
                              fontSize="small"
                              color="inherit"
                            />
                            Call
                          </Typography>
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <TermsAndConditions setShowModal={setShowTCs} showModal={showTCs} />
      {!data?.isPreviewMode && <RedeemOffer />}
    </>
  );
};

function TermsAndConditions({
  setShowModal,
  showModal,
}: {
  setShowModal: (arg: string) => void;
  showModal: string;
}) {
  return (
    <Drawer
      anchor="bottom"
      open={!!showModal}
      onClose={() => setShowModal("")}
      PaperProps={{
        sx: {
          px: 2,
          pb: 4,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h6" sx={{ mt: 4 }} textAlign="left">
          Terms and conditions
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          variant="body1"
          gutterBottom
          fontWeight={400}
          textAlign="left"
        >
          {showModal}
        </Typography>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "18px",
            mt: 3,
            textTransform: "none",
          }}
          onClick={() => setShowModal("")}
        >
          Got it
        </Button>
      </Container>
    </Drawer>
  );
}
const StyledAccordionSummary = styled(AccordionSummary)({
  justifyContent: "left",
  width: "fit-content",
  minHeight: 16,
});

function RedeemOffer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<string>("panel1");
  const [redeemOffer, { isLoading, isSuccess }] = useRedeemOfferMutation();
  const isMobile = useMobile();
  useEffect(() => {
    if (isSuccess) {
      setExpanded("panel3");
    }
  }, [isSuccess]);
  const closeModal = () => {
    const params = new URLSearchParams(document.location.search);
    params.delete("offer");
    setSearchParams(params);
    setExpanded("panel1");
  };
  const offerId = searchParams.get("offer");
  //TODO: convert offerId to number
  //TODO: get offer with offerId
  const { id, brandId } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });

  const category = data?.categorizedMerchants.find(({ merchants }) =>
    merchants.find(({ merchantId: _id }) => _id === Number(brandId)),
  );
  const brand = category?.merchants.find(
    ({ merchantId: _id }) => _id === Number(brandId),
  );
  const offer = brand?.offers.find(({ id: _id }) => _id === Number(offerId));
  if (!offer) return null;
  return (
    <Drawer
      anchor="bottom"
      open={!!offerId}
      onClose={() => {
        closeModal();
      }}
      PaperProps={{
        sx: {
          pt: isMobile && expanded !== "panel4" ? 8 : 0,
          px: 2,
          pb: 4,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
      }}
    >
      {expanded === "panel4" && (
        <DrawerHeader>
          <IconButton size="small" onClick={() => closeModal()}>
            <CloseOutlined />
          </IconButton>
        </DrawerHeader>
      )}
      <Container maxWidth="md">
        <Accordion
          elevation={0}
          expanded={expanded === "panel1"}
          sx={{ "&:before": { height: "0px" } }}
        >
          <Box>{null}</Box>
          <Typography variant="h6" sx={{ mt: 4 }} textAlign="left">
            Are you ready to redeem?
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body1"
            gutterBottom
            fontWeight={400}
            textAlign="left"
          >
            Tapping "start redemption" below will mark this offer as redeemed.
            You will have <b>5 minutes</b> to show your active redemption
            countdown timer to cashier.
          </Typography>
          <Card
            elevation={0}
            sx={{
              borderColor: "divider",
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            <CardContent>
              <Typography variant="caption" textTransform="uppercase">
                {brand?.display_name}
              </Typography>
              <Typography fontWeight="bold">{offer.offer_name}</Typography>
              <Box display="flex" gap={1} sx={{ mb: 2 }}>
                {!offer.number_of_uses ||
                  (offer.number_of_uses - offer.used_count && (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {!offer.number_of_uses
                          ? "Unlimited"
                          : `${offer.number_of_uses - offer.used_count}/${
                              offer.number_of_uses
                            }`}{" "}
                        uses available
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        &bull;
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Expires{" "}
                        {new Date(
                          offer.custom_expiration || data!.expires_on,
                        ).toLocaleDateString()}
                      </Typography>
                    </>
                  ))}
              </Box>
              <Typography variant="caption">
                {offer.restrictions_text || defaultTcs}
              </Typography>
            </CardContent>
          </Card>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 3 }}
            onClick={() => {
              setExpanded("panel2");
            }}
          >
            Start redemption
          </Button>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={expanded === "panel2"}
          sx={{ "&:before": { height: "0px" } }}
        >
          <Box>{null}</Box>
          <Typography variant="h6" sx={{ mt: 4 }} textAlign="left">
            Is the cashier present?
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            variant="body1"
            gutterBottom
            fontWeight={400}
            textAlign="left"
          >
            Only tap ‘Redeem offer’ when you are ready to show your offer to the
            cashier. You will only be able to claim this offer for{" "}
            <b>5 minutes</b> after redeeming.
          </Typography>
          {/** TODO: show loading state when claiming <Loading /> */}
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 3 }}
            disabled={isLoading}
            onClick={() => {
              redeemOffer({ id: Number(id), body: { id: Number(offerId) } });
            }}
            endIcon={
              isLoading ? (
                <CircularProgress size={20} color="secondary" />
              ) : null
            }
          >
            Redeem offer
          </Button>
        </Accordion>
        <Accordion
          elevation={0}
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel3"}
          sx={{
            "&:before": { height: "0px" },
            ".MuiAccordion-region": {
              gap: 1,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Box>{null}</Box>
          {data?.card_image ? (
            <ThreeTwoImg
              sx={{
                mt: 1,
              }}
              src={data?.card_image}
            />
          ) : null}
          <Typography variant="h6" textAlign="left">
            Claim offer
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            fontWeight={400}
            textAlign="left"
          >
            Show your phone to the cashier to claim
          </Typography>
          <Card
            elevation={0}
            sx={{
              borderColor: "divider",
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            <CardContent>
              <Typography variant="caption" textTransform="uppercase">
                {brand?.display_name}
              </Typography>
              <Typography fontWeight="bold">{offer.offer_name}</Typography>
              <Box display="flex" gap={1} sx={{ mb: 1 }}>
                {!offer.number_of_uses ||
                  (offer.number_of_uses - offer.used_count && (
                    <>
                      <Typography variant="body2" color="text.secondary">
                        {!offer.number_of_uses
                          ? "Unlimited"
                          : `${offer.number_of_uses - offer.used_count}/${
                              offer.number_of_uses
                            }`}{" "}
                        uses available
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        &bull;
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Expires{" "}
                        {new Date(
                          offer.custom_expiration || data!.expires_on,
                        ).toLocaleDateString()}
                      </Typography>
                    </>
                  ))}
              </Box>
              <Accordion elevation={0} sx={{ "&:before": { height: "0px" } }}>
                <StyledAccordionSummary
                  sx={{ padding: 0 }}
                  expandIcon={<ExpandMore />}
                >
                  <Typography variant="caption" color="text.secondary">
                    Terms and conditions
                  </Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Typography variant="caption">
                    {offer.restrictions_text || defaultTcs}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <RedemptionMethod {...offer} />
          </Box>
          <Timer onCompleteAction={() => closeModal()} />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => setExpanded("panel4")}
            // onClick={() => closeModal()}
          >
            Complete
          </Button>
        </Accordion>
        <Accordion
          elevation={0}
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel4"}
          sx={{
            "&:before": { height: "0px" },
            ".MuiAccordion-region": {
              gap: 1,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          <Box>{null}</Box>
          <Box
            display="flex"
            justifyContent="center"
            marginBottom={5}
            marginTop={8}
          >
            <img src={high_five} width={180} about="" alt="" />
          </Box>
          <Typography variant="h6" textAlign="left">
            High five!
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            fontWeight={400}
            textAlign="left"
          >
            You’ve successfully redeemed your offer. Here’s to more savings!
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() =>
              Navigation.go(
                AdminPages.cardOptions.path
                  .replace(":id", id!)
                  .replace(":option", "offers"),
              )
            }
          >
            View all offers
          </Button>
        </Accordion>
        {expanded !== "panel3" && expanded !== "panel4" && (
          <Button
            variant="text"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        )}
      </Container>
    </Drawer>
  );
}
export default BrandPage;
