import { Box, styled, type SxProps, type Theme } from "@mui/material";

const ThreeTwoBox = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  paddingBottom: "66.6%", // 3:2 aspect ratio (replace with your desired ratio)
  overflow: "hidden",
  boxShadow: `0 2px 4px 0 #AAAAAA`,
  borderRadius: "10px",
}));

const StyledImg = styled("img")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  borderRadius: 10,
  boxShadow: `0 2px 4px 0 #AAAAAA`,
  boxSizing: "border-box",
  border: "1px solid rgba(255,255,255,0.8)",
}));
export const ThreeTwoImg = ({
  sx,
  ...props
}: {
  sx?: SxProps<Theme>;
  src: string;
}) => {
  return (
    <ThreeTwoBox sx={sx}>
      <StyledImg {...props} />
    </ThreeTwoBox>
  );
};
