import React, { useEffect, useRef } from "react";
import { Button, Box, Typography, useTheme, Link } from "@mui/material";
import { Form, Navigation, RouterLink, useFormik, yup } from "../../lib";
import { ContainerStyle, FormStyle } from "./Auth.styles";
import { actions } from "../../state/rtk-query";
import { useMobile } from "../../themes";
import { PhoneField } from "../../components/fields/PhoneField";
import AuthPages from ".";
import { ActivationCodeField } from "../../components/fields/ActivationCodeField";

const validationSchema = yup.object({
  phone: yup
    .string()
    .min(10, "Phone should be of minimum 10 characters length")
    .required("Phone is required"),
  activation_code: yup
    .string()
    .min(9, "Activation code should be 9 characters long")
    .required("Activation code is required"),
});
const { useRequestOtpWithCodeMutation } = actions;

export const LoginWithCodePage = React.memo(function LoginWCodePage() {
  const [requestOtp, { isSuccess }] = useRequestOtpWithCodeMutation();

  const frm = useFormik({
    initialValues: {
      phone: "",
      activation_code: "",
    },
    validationSchema,
    async onSubmit(values) {
      await requestOtp({ body: values });
    },
  });
  useEffect(() => {
    if (isSuccess) {
      Navigation.go(
        AuthPages.codeSent.path +
          `?phone=${encodeURIComponent(frm.values.phone)}`,
      );
    }
  }, [isSuccess]);
  const targetRef = useRef<HTMLHeadingElement>(null);
  const isMobile = useMobile();
  const theme = useTheme();
  return (
    <ContainerStyle>
      <Box
        sx={{
          width: 1,
          borderRadius: { xs: "20px 20px 0 0", sm: "20px" },
          backgroundColor: "#FFFFFF",
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <FormStyle>
          <Form form={frm}>
            <Typography
              variant="h3"
              gutterBottom
              fontWeight={500}
              fontSize={isMobile ? 24 : 28}
              textAlign="left"
            >
              Access your discount card(s)
            </Typography>
            <Typography
              sx={{ my: 2 }}
              variant="body1"
              gutterBottom
              fontWeight={400}
              textAlign="left"
            >
              Enter the activation code you received.{" "}
            </Typography>
            <ActivationCodeField />
            <Typography
              sx={{ my: 2 }}
              variant="body1"
              gutterBottom
              fontWeight={400}
              textAlign="left"
            >
              Enter a phone number for future access.{" "}
            </Typography>
            <PhoneField />
            <div ref={targetRef}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={frm.isSubmitting || !frm.dirty || !frm.isValid}
                sx={{
                  mt: 3,
                }}
              >
                Access cards
              </Button>
            </div>
            <Typography
              sx={{
                color: "#5E5E5E",
                fontSize: 10,
                textAlign: "left",
                mt: 2,
              }}
            >
              We will text you an access code to authorize the card. You will
              receive one message only. You will only receive another message if
              you make another request.
            </Typography>
            <Typography
              sx={{
                color: "#5E5E5E",
                fontSize: 10,
                textAlign: "left",
                mb: 2,
              }}
            >
              Message and data rates may apply. The phone number you provide
              here is used solely for this one-time verification and is never
              shared by InstaRaise.{" "}
            </Typography>
          </Form>
          <Box display="flex" mt={2} gap={1}>
            <Typography>Don’t have a code?</Typography>
            <Link
              to={AuthPages.login.path}
              style={{
                color: theme.palette.primary.main,
                textDecoration: "none",
                textAlign: "left",
                fontWeight: 500,
                fontSize: 14,
                lineHeight: "24px",
                font: "Graphik Semibold",
                display: "block",
              }}
              component={RouterLink}
            >
              Login with phone
            </Link>
          </Box>
        </FormStyle>
      </Box>
    </ContainerStyle>
  );
});
