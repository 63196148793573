import { useMemo } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AdminPages from "../../pages/admin";
import DeviceUnknownIcon from "@mui/icons-material/DeviceUnknown";
import { RouterLink, useLocation } from "../../lib";
import { matchPath, useParams } from "react-router-dom";
import { Paper, useTheme } from "@mui/material";

export default function SimpleBottomNavigation() {
  const { pathname } = useLocation();
  const params = useParams();
  const theme = useTheme();
  const index = useMemo(
    () =>
      Object.values(AdminPages).findIndex(
        (p) =>
          !!matchPath(p.path, pathname) ||
          !!p.childrenPaths?.find((p) => matchPath(p, location.pathname)),
      ),
    [pathname],
  );
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1200 }} //TODO: make sure the zIndex remains higher than any list that has a position sticky
      elevation={4}
    >
      <BottomNavigation
        showLabels
        value={index}
        sx={{
          ".Mui-selected": {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            color: theme.palette.primary.main,
            fontSize: 12,
          },
        }}
      >
        {Object.values(AdminPages)
          .filter((p) => Boolean(p.Icon))
          .map(
            ({ title, Icon = DeviceUnknownIcon, path, initialParams }, i) => {
              let _path = path;
              if (params) {
                for (const [key, value] of Object.entries(params)) {
                  _path = _path.replace(`:${key}`, value as string);
                }
              }
              if (_path.includes(":") && initialParams) {
                for (const [key, value] of Object.entries(initialParams)) {
                  _path = _path.replace(`:${key}`, value);
                }
              }

              return (
                <BottomNavigationAction
                  key={i}
                  to={_path}
                  component={RouterLink}
                  label={title}
                  icon={<Icon />}
                  sx={{
                    "&.Mui-selected": {
                      "& .MuiBottomNavigationAction-label": {
                        fontSize: "inherit",
                      },
                    },
                    textAlign: "center",
                    p: 0,
                    ".MuiBottomNavigationAction-label": {
                      fontSize: 12,
                      textWrap: "nowrap",
                    },
                  }}
                />
              );
            },
          )}
      </BottomNavigation>
    </Paper>
  );
}
