import {
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import AdminPages from "../../pages/admin";
import { RouterLink } from "../../lib";
import { useParams } from "react-router-dom";

type OfferCardProps = {
  id: number;
  offer_name: string;
  custom_expiration: null | string;
  expires_on: string;
  number_of_uses: number | null;
  restrictions_text: null | string;
  estimated_value_per_use: string;
  redemption_method: "Manual" | "Code" | "Qr code" | "Barcode";
  redemption_code: null | string;
  used_count: number;
  /** Brand/merchant information */
  merchantId: number;
  logo: null | string;
  display_name: string;
};

function Uses(props: {
  usedUp: boolean;
  number_of_uses: number | null;
  used_count: number;
}) {
  if (props.usedUp) {
    return (
      <Typography variant="body2" color="error.main" component="span">
        Redeemed {props.used_count}/{props.number_of_uses} times
      </Typography>
    );
  }
  return (
    <Typography variant="body2" color="text.secondary" component="span">
      {props.number_of_uses
        ? props.number_of_uses + " use" + (props.number_of_uses > 1 ? "s" : "")
        : "Unlimited"}
    </Typography>
  );
}
function Expiration(props: {
  custom_expiration: null | string;
  expires_on: string;
  isExpired: boolean;
}) {
  return (
    <Typography
      variant="body2"
      color={props.isExpired ? "error.main" : "text.secondary"}
      component="span"
    >
      {props.isExpired ? "Expired" : "Expires"}{" "}
      {new Date(
        props.custom_expiration ?? props!.expires_on,
      ).toLocaleDateString()}
    </Typography>
  );
}
export function OfferCard(props: OfferCardProps) {
  const isExpired =
    new Date(props.custom_expiration ?? props.expires_on) < new Date();
  const usedUp =
    !!props.number_of_uses && props.used_count >= props.number_of_uses;

  return (
    <ListItem
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 2,
        opacity: isExpired || usedUp ? 0.5 : 1,
      }}
      key={props.id}
    >
      {props.logo ? (
        <ListItemIcon
          sx={{
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
            bgcolor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "8px",
          }}
        >
          <img
            src={props.logo}
            alt={props.display_name}
            style={{
              width: "64px",
              height: "64px",
              objectFit: "contain",
            }}
          />
        </ListItemIcon>
      ) : null}
      <ListItemText
        sx={{ p: 0, m: 0, mt: -1 }}
        primary={
          <Typography
            variant="caption"
            color="text.secondary"
            textTransform="uppercase"
          >
            {props.display_name}
          </Typography>
        }
        secondary={
          <>
            <Typography
              fontWeight="bold"
              component="span"
              sx={{ display: "block" }}
            >
              {props.offer_name}
            </Typography>
            <>
              {!isExpired && (
                <Uses
                  usedUp={usedUp}
                  used_count={props.used_count}
                  number_of_uses={props.number_of_uses}
                />
              )}
              {!isExpired && !usedUp && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                  sx={{ mx: 1 }}
                >
                  &bull;
                </Typography>
              )}
              {!usedUp && (
                <Expiration
                  custom_expiration={props.custom_expiration}
                  expires_on={props.expires_on}
                  isExpired={isExpired}
                />
              )}
            </>
          </>
        }
      />
    </ListItem>
  );
}

export function OfferCardLink(props: OfferCardProps) {
  const { id } = useParams();
  const isExpired =
    new Date(props.custom_expiration ?? props.expires_on) < new Date();
  const usedUp =
    !!props.number_of_uses && props.used_count >= props.number_of_uses;
  if (isExpired || usedUp) return <OfferCard {...props} />;
  return (
    <Link
      key={props.id}
      component={RouterLink}
      to={AdminPages.brand.path
        .replace(":id", id!)
        .replace(":brandId", props.merchantId.toString())}
      sx={{ textDecoration: "none", color: "inherit" }}
    >
      <OfferCard {...props} />
    </Link>
  );
}
