import React, { useEffect, useRef, useState } from "react";
import { Button, Box, Typography, useTheme, Link, Drawer } from "@mui/material";

import { Form, Navigation, RouterLink, useFormik, yup } from "../../lib";

import { ContainerStyle, FormStyle } from "./Auth.styles";
import { actions } from "../../state/rtk-query";
import { useMobile } from "../../themes";
import { PhoneField } from "../../components/fields/PhoneField";
import AuthPages from ".";

const validationSchema = yup.object({
  phone: yup
    .string()
    .min(10, "Phone should be of minimum 10 characters length")
    .required("Phone is required"),
});

export const LoginPage = React.memo(function LoginPage() {
  const { useRequestOtpCodeMutation } = actions;
  const [requestOtp, { isSuccess }] = useRequestOtpCodeMutation();

  const frm = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema,
    async onSubmit(values) {
      await requestOtp({ body: values });
    },
  });
  useEffect(() => {
    if (isSuccess) {
      Navigation.go(
        AuthPages.codeSent.path +
          `?phone=${encodeURIComponent(frm.values.phone)}`,
      );
    }
  }, [isSuccess]);
  const targetRef = useRef<HTMLHeadingElement>(null);
  const isMobile = useMobile();
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  return (
    <ContainerStyle>
      <Box
        sx={{
          width: 1,
          borderRadius: { xs: "20px 20px 0 0", sm: "20px" },
          backgroundColor: "#FFFFFF",
          paddingRight: 4,
          paddingLeft: 4,
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          fontWeight={500}
          fontSize={isMobile ? 24 : 28}
          sx={{ mt: 4 }}
          textAlign="left"
        >
          Access your discount card(s)
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          variant="body1"
          gutterBottom
          fontWeight={400}
          textAlign="left"
        >
          Enter the phone number that you used to purchase your card(s).
        </Typography>
        <FormStyle>
          <Form form={frm}>
            <PhoneField />
            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Link
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                  textAlign: "right",
                  fontWeight: 500,
                }}
                href="#"
                onClick={() => setShowModal(true)}
              >
                Forgot phone number?
              </Link>
            </Box>
            <div ref={targetRef}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={frm.isSubmitting || !frm.dirty || !frm.isValid}
                sx={{
                  mt: 3,
                }}
              >
                Access cards
              </Button>
            </div>
            <Typography
              sx={{
                color: "#5E5E5E",
                fontSize: 10,
                textAlign: "left",
                mt: 2,
              }}
            >
              You will receive one message only. You will only receive another
              message if you make another request.
            </Typography>
            <Typography
              sx={{
                color: "#5E5E5E",
                fontSize: 10,
                textAlign: "left",
                mb: 2,
              }}
            >
              Message and data rates may apply. Reply STOP to opt out. Reply
              HELP for assistance. The phone number you provide here is used
              solely for this one-time verification and is never shared by
              InstaRaise.
            </Typography>
          </Form>
          <Link
            to={AuthPages.loginWithCode.path}
            style={{
              color: theme.palette.primary.main,
              textDecoration: "none",
              textAlign: "left",
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "24px",
              font: "Graphik Semibold",
              display: "block",
            }}
            component={RouterLink}
          >
            Have an activation code?
          </Link>
        </FormStyle>
      </Box>
      <Drawer
        anchor="bottom"
        open={showModal}
        onClose={() => setShowModal(false)}
        PaperProps={{
          sx: {
            px: 2,
            pb: 4,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
      >
        <Typography
          variant="h3"
          fontWeight={500}
          fontSize={isMobile ? 24 : 28}
          sx={{ mt: 4 }}
          textAlign="left"
        >
          Forgot your phone?
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          variant="body1"
          gutterBottom
          fontWeight={400}
          textAlign="left"
        >
          You can find the phone number that you used to purchase the card in
          the purchase receipt email sent to you when you purchased the card.
          For further assistance, contact support at{" "}
          <a
            style={{
              color: theme.palette.text.primary,
              textDecoration: "none",
            }}
            href="mailto:info@instaraise.com"
          >
            info@instaraise.com
          </a>
          .
        </Typography>
        <Button
          variant="contained"
          type="submit"
          disabled={frm.isSubmitting}
          fullWidth
          sx={{
            backgroundColor: "primary.main",
            borderRadius: "18px",
            mt: 3,
            textTransform: "none",
          }}
          onClick={() => setShowModal(false)}
        >
          Back to login
        </Button>
      </Drawer>
    </ContainerStyle>
  );
});
