import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { StyledCard } from "../../pages/admin/DashboardPage";
import { GetCardsApiResponse } from "../../state/rtk-query/state/admin";

export const Card = (
  data: GetCardsApiResponse[number] & {
    action: {
      title: string;
      onClick: () => void;
    };
  },
) => {
  const isExpired = new Date(data.expires_on) < new Date();
  return (
    <Grid item xs={12} key={data.id}>
      <StyledCard sx={{ borderRadius: 2, opacity: isExpired ? 0.5 : 1 }}>
        {data.card_image ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingBottom: "66.6%", // 3:2 aspect ratio (replace with your desired ratio)
              overflow: "hidden",
            }}
          >
            <img
              src={data.card_image}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              alt=""
            />
          </Box>
        ) : null}
        <CardContent sx={{ borderRadius: 10 }}>
          <Typography
            fontWeight={"medium"}
            component="div"
            color="text.secondary"
          >
            {data.card_name}
          </Typography>
          <Typography
            sx={{ fontSize: 12 }}
            color={isExpired ? "error.main" : "text.secondary"}
            gutterBottom
          >
            {isExpired ? "Expired" : "Expires"}:{" "}
            {new Date(data.expires_on).toLocaleDateString()}
          </Typography>
          <Divider sx={{ mt: 2 }} />
          {data.display_estimated_values &&
          (data.estimated_card_value || data.estimated_redeemed_value) ? (
            <Box sx={{ display: "flex", minHeight: 61 }}>
              {data.estimated_card_value ? (
                <Box
                  sx={{
                    textAlign: "center",
                    flexGrow: 1,
                    py: 1,
                    width: data.estimated_redeemed_value ? "50%" : 1,
                  }}
                >
                  <Typography
                    fontWeight={"medium"}
                    component="div"
                    color="text.secondary"
                  >
                    {data.estimated_card_value}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 12 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Estimated value
                  </Typography>
                </Box>
              ) : null}
              {data.display_estimated_values &&
              data.estimated_card_value &&
              data.estimated_redeemed_value ? (
                <Divider orientation="vertical" flexItem />
              ) : null}
              {data.estimated_redeemed_value ? (
                <Box
                  sx={{
                    textAlign: "center",
                    flexGrow: 1,
                    py: 1,
                    maxWidth: data.estimated_card_value ? "50%" : 1,
                  }}
                >
                  <Typography
                    fontWeight={"medium"}
                    component="div"
                    color="text.secondary"
                  >
                    {data.estimated_redeemed_value}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 12 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Estimated redeemed value
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}
          {data.display_estimated_values && <Divider />}
        </CardContent>
        <CardActions>
          <Button
            size="large"
            fullWidth
            variant="contained"
            sx={{ mb: 1, mx: 2 }}
            onClick={() => data.action.onClick()}
          >
            {data.action.title}
          </Button>
        </CardActions>
      </StyledCard>
    </Grid>
  );
};
