import React, { useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { authSelectors, uiSelectors, useSelector } from "../../state";
import { MenuIcon } from "../../components";
import { CreditScoreOutlined, Logout, PrivacyTip } from "@mui/icons-material";
import { NewCardContext } from "../../context";

function MenuList({
  anchorEl,
  handleClose,
}: {
  anchorEl?: Element | ((element: Element) => Element) | null | undefined;
  handleClose: () => void;
}) {
  const isRNWebView = useMemo(() => {
    return window.isRNWebView;
  }, []);
  const [, showActivateCard] = useContext(NewCardContext);
  return (
    <Menu
      id="admin-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          showActivateCard(true);
          handleClose();
        }}
      >
        <ListItemIcon>
          <CreditScoreOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>Activate new card</ListItemText>
      </MenuItem>
      {isRNWebView ? (
        <MenuItem
          component="a"
          href="https://www.instaraise.com/privacy"
          target="_blank"
          onClick={handleClose}
        >
          <ListItemIcon>
            <PrivacyTip fontSize="small" />
          </ListItemIcon>
          <ListItemText>Privacy</ListItemText>
        </MenuItem>
      ) : null}
      <MenuItem onClick={handleClose} component={Link} to="/admin/logout">
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
}
const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userInfo = useSelector(authSelectors.userInfo);
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <ListItemButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ListItemIcon>
          <Avatar sx={{ width: 24, height: 24, fontSize: 14 }}>
            {userInfo?.first_name?.charAt(0)}
          </Avatar>
        </ListItemIcon>
        {menuOpen && (
          <ListItemText
            sx={{ fontSize: 14 }}
            primary={`${userInfo.first_name} ${userInfo.last_name}`}
          />
        )}
      </ListItemButton>
      <MenuList anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};

export const HamburgerMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="logout"
        size="small"
      >
        <MenuIcon />
      </IconButton>
      <MenuList anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};
export default ProfileMenu;
