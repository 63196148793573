import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  authActions,
  authSelectors,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import MiniDrawer from "./MiniDrawer";
import PermanentDrawer from "./PermanentDrawer";
import Notification from "../../components/Notification";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { COLLAPSIBLE_DRAWER } from "../../config";
import { MainContentStyled } from "./AdminLayout.styles";
import { useMobile } from "../../themes";
import { Navigation } from "../../lib";
import BottomTabBar from "./BottomTabBar";
// import DynamicMobileTopBar from "./DynamicMobileTopBar";
import MobileTopBar from "./MobileTopBar";
import { AuthState } from "../../state/auth/state";
import TopBar from "./TopBar";
import { NewCardContext } from "../../context";
import { ActivateNewCard } from "../../components/drawer/ActivateNewCard";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DrawerHeader } from "../../components/drawer";

export const AdminLayout = React.memo(function () {
  const { id } = useParams();
  const loading = useSelector(uiSelectors.loading);
  const menuOpen = useSelector(uiSelectors.menuOpen);
  const isMobile = useMobile();
  const requiresMfa = useSelector(authSelectors.requiresMfa);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  useEffect(() => {
    if (params.has("jwt")) {
      const t = params.get("jwt")!;
      const authState: AuthState = {
        token: t,
      };
      dispatch(authActions.setAuthState(authState));
      params.delete("jwt");
      setParams(params);
    }
  }, [params.get("jwt")]);

  useEffect(() => {
    if (requiresMfa === true) {
      Navigation.go("/auth/multi-factor-auth");
    }
  }, [requiresMfa]);
  const newCardState = useState(false);
  const [state, setState] = newCardState;
  return (
    <NewCardContext.Provider value={newCardState}>
      <CssBaseline />
      {isMobile ? (
        <>
          <MobileTopBar />
        </>
      ) : !id ? (
        <TopBar noDrawer />
      ) : COLLAPSIBLE_DRAWER ? (
        <MiniDrawer />
      ) : (
        <PermanentDrawer />
      )}
      {/* Figure out if the ml is always correct feels hacky */}
      <MainContentStyled
        sx={{
          pt: isMobile ? 12 : 0,
          pl: isMobile || !id ? 0 : menuOpen ? 36 : 14,
          pr: isMobile ? 0 : 6,
          pb: isMobile ? 0 : 4,
        }}
      >
        {isLoggedIn && !params.has("jwt") && <Outlet />}
        {isMobile && <Box sx={{ height: "64px" }} />}
      </MainContentStyled>

      {isMobile && id && <BottomTabBar />}
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
      <Drawer
        anchor="bottom"
        open={state}
        onClose={() => setState(false)}
        PaperProps={{
          sx: {
            px: 2,
            pb: 4,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            maxHeight: "75vh",
          },
        }}
      >
        <DrawerHeader>
          <IconButton size="small" onClick={() => setState(false)}>
            <CloseOutlinedIcon />
          </IconButton>
        </DrawerHeader>
        <ActivateNewCard />
      </Drawer>
    </NewCardContext.Provider>
  );
});
