import React, { useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { useMobile, useTablet } from "../../themes";
import { DashboardCard } from "../../components/cards/DashboardCard";
import emptyCart from "../../assets/svg/empty_cart.svg";
import { actions } from "../../state/rtk-query";

const { useGetCardsQuery } = actions;

export const StyledCard = styled(Card)(() => ({
  width: "100%",
  textAlign: "left",
  margin: "0 auto",
  maxWidth: "90vw",
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 36,
  fontWeight: 700,
  marginBottom: 32,
  textTransform: "uppercase",
  lineHeight: 1,
}));
export const StyledContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 24,
}));

export const DashboardPage = React.memo(function DashboardPage() {
  const { data: cards } = useGetCardsQuery();
  const [hideInactiveCards, setHideInactiveCards] = useState(true);
  const isTablet = useTablet();
  const isMobile = useMobile();
  return (
    <Container maxWidth="md">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ ml: { sm: 0, xs: 2 } }}
          variant={isTablet || isMobile ? "h6" : "h4"}
        >
          All Cards
        </Typography>
        <FormControlLabel
          control={<Checkbox checked={hideInactiveCards} />}
          onChange={(_e, checked) => {
            setHideInactiveCards(checked);
          }}
          label="Hide inactive"
        />
      </Box>
      {!cards ||
        (cards?.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "center",
              height: "50vh",
            }}
          >
            {!cards && <Typography>Loading...</Typography>}
            {cards?.length === 0 && (
              <>
                <img src={emptyCart} style={{ height: 180 }} alt="Empty cart" />
                <Typography sx={{ m: 2, fontSize: 34 }}>
                  No cards found
                </Typography>
              </>
            )}
          </Box>
        ))}
      {cards && cards.length > 0 && (
        <>
          <Typography sx={{ mb: { md: 4, xs: 4 }, ml: { sm: 0, xs: 2 } }}>
            Select a card to view your offers
          </Typography>

          <Grid container spacing={3}>
            {cards
              .filter((card) =>
                hideInactiveCards
                  ? new Date() < new Date(card.expires_on)
                  : true,
              )
              .map(DashboardCard)}
          </Grid>
        </>
      )}
    </Container>
  );
});
