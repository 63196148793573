import * as msal from "@azure/msal-browser";
import { AppThunk } from "..";
import { authClient, uiActions } from "../states";
import { admin } from "./state";
import {
  VITE_CLIENT_ID,
  VITE_AAD_ENDPOINT,
  VITE_TENANT_ID,
} from "../../config";
const redirectUri = "http://localhost:3303/"; //Configure to FE URL
const { actions } = admin;

/* Microsoft authentication options */
const myMSALObj = new msal.PublicClientApplication({
  auth: {
    clientId: VITE_CLIENT_ID,
    authority: `${VITE_AAD_ENDPOINT}/${VITE_TENANT_ID}`,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage", //persist login even after browser closes
  },
});
const request = {
  headers: { Origin: redirectUri },
  //default scope includes all permissions given to this API
  scopes: [".default"],
};

export const adminActions = {
  ...actions,
  /**GSuite start */

  getOAuthTokens(params: Record<string, any>): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.post(`/google/oauth-code`, params);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Hang tight"));
      } else {
        dispatch(uiActions.showError("failed to getOAuthTokens"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGoogleCalendars(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(`/google/calendars`);
      if (status === 200) {
        dispatch(actions.setGoogleCalendars(data.calendars));
      } else {
        dispatch(uiActions.showError("failed to getGoogleCalendars"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  /**GSuite End */
  /**Microsoft start */
  loginMicrosoftUser(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      await myMSALObj
        .loginPopup(request)
        .then(async (response) => {
          myMSALObj.setActiveAccount(response.account);
          if (response.accessToken === null) {
            await myMSALObj
              .acquireTokenSilent(request)
              .then(() => {
                dispatch(actions.setMicrosoftAccessToken(true));
              })
              .catch((error) => {
                dispatch(uiActions.showError(error.errorMessage));
              });
            dispatch(uiActions.setLoading(false));
          } else {
            dispatch(actions.setMicrosoftAccessToken(true));
          }
        })
        .catch((error) => {
          dispatch(uiActions.showError(error.errorMessage));
        });
      dispatch(uiActions.setLoading(false));
    };
  },
  logoutMicrosoftUser(): AppThunk {
    return async (dispatch) => {
      const account = myMSALObj.getActiveAccount();
      if (account) {
        const logoutRequest = {
          account: account,
        };
        dispatch(uiActions.setLoading(true));
        await myMSALObj.logoutPopup(logoutRequest);
        dispatch(actions.setMicrosoftAccessToken(false));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  checkMicrosoftAuthStatus(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      if (myMSALObj.getActiveAccount())
        dispatch(actions.setMicrosoftAccessToken(true));
      dispatch(uiActions.setLoading(false));
    };
  },
  getMicrosoftFrontEndData(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let errorMessage;
      let accessToken;
      await myMSALObj
        .acquireTokenSilent(request) //refreshes access token for logged-in user
        .then(async (response) => {
          accessToken = response.accessToken;
        })
        .catch(async (error) => {
          errorMessage = error;
        });
      if (accessToken) {
        await fetch(`https://graph.microsoft.com/v1.0/me/drive/root/children`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Origin: redirectUri,
          },
        }).then(async (res) => {
          if (res.ok) {
            const data = await res.json();
            if (data.value) {
              dispatch(actions.setMicrosoftFrontEndData(data.value));
            }
          } else {
            dispatch(uiActions.showError(res.statusText));
          }
        });
      } else
        dispatch(
          uiActions.showError(
            errorMessage || "failed to getMicrosoft frontend data",
          ),
        );
      dispatch(uiActions.setLoading(false));
    };
  },
  getMicrosoftBackEndData(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let errorMessage;
      let accessToken;
      await myMSALObj
        .acquireTokenSilent(request) //refreshes access token for logged-in user
        .then(async (response) => {
          accessToken = response.accessToken;
        })
        .catch(async (error) => {
          errorMessage = error;
        });
      if (accessToken) {
        const response = await authClient.get(`/microsoft/backend-data`, {
          headers: {
            Authorization: `${accessToken}`,
          },
        });
        const data = response.data;
        if (data.value) dispatch(actions.setMicrosoftBackEndData(data.value));
      } else
        dispatch(
          uiActions.showError(
            errorMessage || "failed to getMicrosoft backend data",
          ),
        );
      dispatch(uiActions.setLoading(false));
    };
  },
};
