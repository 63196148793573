import {
  HomeOutlined as HomeIcon,
  DashboardCustomizeOutlined as DashboardIcon,
  LocalOfferOutlined as LocalOfferIcon,
  MoreHorizOutlined as MoreHorizOutlinedIcon,
} from "@mui/icons-material";
import { DashboardPage } from "./DashboardPage";
import ProfilePage from "./ProfilePage";
import CardPage from "./CardPage";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { NamedExoticComponent } from "react";
import CardPageOptions from "./CardPageOptions";
import BrandPage from "./BrandPage";
import { LogoutPage } from "../auth/LogoutPage";

interface Page {
  anon: boolean;
  path: string;
  title: string;
  view: ((props: object) => JSX.Element) | NamedExoticComponent<object>;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  menuOrder?: number;
  childrenPaths?: string[];
  initialParams?: { [key: string]: string };
}
const cardOptionsPath = "/admin/cards/:id/:option";
const brandPath = "/admin/cards/:id/brands/:brandId";
export const AdminPages: Record<string, Page> = {
  dashboard: {
    anon: false,
    path: "/admin",
    title: "All cards",
    view: DashboardPage,
    Icon: HomeIcon,
    menuOrder: 1,
  },
  card: {
    anon: false,
    path: "/admin/cards/:id",
    title: "Card overview",
    view: CardPage,
    Icon: DashboardIcon,
    menuOrder: 2,
  },
  cardOptions: {
    anon: false,
    path: cardOptionsPath,
    title: "Offers",
    Icon: LocalOfferIcon,
    menuOrder: 3,
    view: CardPageOptions,
    initialParams: { option: "offers" },
    childrenPaths: [brandPath],
  },
  more: {
    anon: false,
    path: "/admin/more/:id",
    title: "More",
    menuOrder: 4,
    view: ProfilePage,
    Icon: MoreHorizOutlinedIcon,
  },
  brand: {
    anon: false,
    path: brandPath,
    title: "Brand page",
    view: BrandPage,
  },
  logout: {
    anon: true,
    path: "/admin/logout",
    title: "Logout",
    view: LogoutPage,
  },
};

export default AdminPages;
