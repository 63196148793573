import { LoginPage } from "./LoginPage";
import { MultifactorAuthPage } from "./MultifactorAuthPage";
import { CodeSentPage } from "./CodeSent";
import { LoginWithCodePage } from "./LoginWithCodePage";

export const AuthPages = {
  multiFactorAuth: {
    anon: true,
    path: "/auth/multi-factor-auth",
    title: "Multifactor Auth",
    view: MultifactorAuthPage,
  },
  login: {
    anon: true,
    path: "/auth/login",
    title: "Login",
    view: LoginPage,
  },
  loginWithCode: {
    anon: true,
    path: "/auth/login-with-code",
    title: "Login with code",
    view: LoginWithCodePage,
  },
  codeSent: {
    anon: true,
    path: "/auth/code-sent",
    title: "Code Sent",
    view: CodeSentPage,
  },
};

export default AuthPages;
