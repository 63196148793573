import { styled } from "@mui/material/styles";

export const MainContentStyled = styled(`div`)(({ theme }) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "rgba(0,0,0,0.03)",
  minHeight: "100%",
  overflow: "visible",
  paddingTop: 16,
}));
