import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { ThreeTwoImg } from "../img/ThreeTwo";

export function TeamMessage() {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  useEffect(() => {
    let url = null;
    if (data?.org_video_link) {
      if (data?.org_video_link.includes("youtube.com/embed")) {
        url = `${data?.org_video_link}?rel=0`;
      } else if (data?.org_video_link.includes("youtube.com")) {
        const youtubeQueryId = queryString.parseUrl(data?.org_video_link).query
          .v;
        if (youtubeQueryId) {
          url = `https://www.youtube.com/embed/${youtubeQueryId}?rel=0`;
        }
      } else if (data?.org_video_link.includes("youtu.be")) {
        const youtubeId = data?.org_video_link.substring(
          data?.org_video_link.lastIndexOf("/") + 1,
        );
        url = `https://www.youtube.com/embed/${youtubeId}?rel=0`;
      } else if (data?.org_video_link.includes("vimeo.com/event")) {
        url = `${data?.org_video_link}/embed`;
      } else if (data?.org_video_link.includes("vimeo.com")) {
        const vimeoId = data?.org_video_link.substring(
          data?.org_video_link.lastIndexOf("/") + 1,
        );
        if (vimeoId) url = `https://player.vimeo.com/video/${vimeoId}`;
      }
      setVideoUrl(url);
    }
  }, [data?.org_video_link]);
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 6, display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h6">
          {data?.org_name ? `Message from ${data?.org_name}` : "Our Message"}
        </Typography>
        <Typography variant="body1">{data?.org_paragraph}</Typography>
        {data?.org_picture ? (
          <ThreeTwoImg
            sx={{
              mb: 2,
            }}
            src={data?.org_picture}
          />
        ) : null}
        {videoUrl ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.1%", // 16:9 aspect ratio (replace with your desired ratio)
              overflow: "hidden",
              mb: 2,
              boxShadow: `0 2px 4px 0 #AAAAAA`,
              borderRadius: "10px",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
                objectFit: "cover",
                borderRadius: 10,
                boxShadow: `0 2px 4px 0 #AAAAAA`,
                boxSizing: "border-box",
                border: "1px solid rgba(255,255,255,0.8)",
              }}
              src={videoUrl}
            />
          </Box>
        ) : null}
      </Box>
    </Container>
  );
}
