import { useMediaQuery, createTheme } from "@mui/material";

/** Material-ui default theme, to copy from where necessary. */
// const defaults = createMuiTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createTheme({
  palette: {
    // type: "light",
    primary: { main: "#248BEF", light: "#f3f4fa", contrastText: "#ffffff" },
    secondary: { main: "#f50057" },
    text: { primary: "#212121", secondary: "#5E5E5E" },
  },
  typography: {
    fontFamily: [
      "Graphik Web",
      "sans-serif",
      "Roboto",
      "Helvetica",
      "Arial",
    ].join(","),
  },
  // #region Example global overrides for this theme:
  components: {
    //   // Example to override "contained" variant of the Material-ui Button:
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "20px",
          backgroundColor: "primary.main",
          //   //     boxShadow: "none",
          //   //     "&:hover": {
          //   //       boxShadow: "none",
          //   //     },
        },
      },
    },

    //   // Example to override the "contained" variant of Material-ui ButtonGroup:
    //   // MuiButtonGroup: {
    //   //   contained: {
    //   //     boxShadow: "none",
    //   //     border: "1px solid rgba(0,0,0,0.16)",
    //   //   },
    //   // },

    //   // Example to override the paper style of the Material-ui Menu:
    //   // MuiMenu: {
    //   //   paper: {
    //   //     boxShadow,
    //   //   },
    //   // },
  },
  // #endregion

  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion

  // Example to set custom shadows:
  // shadows,
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "(max-width:600px)") {
  return useMediaQuery(size);
}
export function useTablet() {
  return useMobile("(min-width: 600px) and (max-width:921px)");
}
