import { api } from "../api";
export const addTagTypes = ["google", "calendar"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createGoogleAccessToken: build.mutation<
        CreateGoogleAccessTokenApiResponse,
        CreateGoogleAccessTokenApiArg
      >({
        query: () => ({ url: `/google/oauth-code`, method: "POST" }),
        invalidatesTags: ["google"],
      }),
      getGoogleCalendars: build.query<
        GetGoogleCalendarsApiResponse,
        GetGoogleCalendarsApiArg
      >({
        query: () => ({ url: `/google/calendars` }),
        providesTags: ["google", "calendar"],
      }),
      getGoogleCredentials: build.query<
        GetGoogleCredentialsApiResponse,
        GetGoogleCredentialsApiArg
      >({
        query: () => ({ url: `/google/sign-in-creds` }),
        providesTags: ["google", "calendar"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type CreateGoogleAccessTokenApiResponse =
  /** status 204 An empty response */ undefined;
export type CreateGoogleAccessTokenApiArg = void;
export type GetGoogleCalendarsApiResponse =
  /** status 200 Generic response */ object;
export type GetGoogleCalendarsApiArg = void;
export type GetGoogleCredentialsApiResponse =
  /** status 200 Generic response */ object;
export type GetGoogleCredentialsApiArg = void;
export const {
  useCreateGoogleAccessTokenMutation,
  useGetGoogleCalendarsQuery,
  useLazyGetGoogleCalendarsQuery,
  useGetGoogleCredentialsQuery,
  useLazyGetGoogleCredentialsQuery,
} = injectedRtkApi;
