import AdminPages from "../../pages/admin";
import { Navigation, RouterLink } from "../../lib";
import { Box, Button, Link, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";
import { useEffect, useMemo, useState } from "react";
import { ThreeTwoImg } from "../img/ThreeTwo";

export const BottomOfHeader = () => {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const isExpired = useMemo(() => {
    if (data?.expires_on) return new Date(data?.expires_on) < new Date();
    return false;
  }, [data?.expires_on]);

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      {!!data?.org_logo && (
        <img
          src={data.org_logo}
          style={{
            width: 32,
            height: 32,
            borderRadius: "50%",
            marginRight: 12,
            marginTop: 4,
          }}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
        <Typography
          fontWeight="medium"
          variant="body1"
          component="div"
          color="text.secondary"
        >
          {data?.card_name}
        </Typography>
        <Box>
          {data?.org_name ? (
            <Typography
              variant="caption"
              color="text.secondary"
              component="span"
            >
              By {data.org_name}
            </Typography>
          ) : null}
          {data?.org_paragraph && (
            <Link
              component={RouterLink}
              to={`/admin/more/${data.id}#message`}
              sx={{
                ml: 0.5,
                textDecoration: "none",
                textAlign: "right",
                fontWeight: 500,
                fontSize: 12,
              }}
            >
              View our message
            </Link>
          )}
        </Box>
        <Typography
          variant="caption"
          color={isExpired ? "error.main" : "text.secondary"}
        >
          {isExpired ? "Expired" : "Expires"}:{" "}
          {data?.expires_on
            ? new Date(data?.expires_on).toLocaleDateString()
            : null}
        </Typography>
      </Box>
    </Box>
  );
};
async function get_average_rgb(src: string): Promise<Uint8ClampedArray> {
  /* https://stackoverflow.com/questions/2541481/get-average-color-of-image-via-javascript */
  return new Promise((resolve) => {
    const context = document.createElement("canvas").getContext("2d");
    context!.imageSmoothingEnabled = true;

    const img = new Image();
    img.src = src;
    img.crossOrigin = "";

    img.onload = () => {
      context!.drawImage(img, 0, 0, 1, 1);
      resolve(context!.getImageData(0, 0, 1, 1).data.slice(0, 3));
    };
  });
}
export const HeaderCard = () => {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  const [bgColor, setBgColor] = useState<string>("#FFFFFF");
  useEffect(() => {
    if (data?.card_image) {
      get_average_rgb(data.card_image).then((rgb) => {
        setBgColor(`rgba(${rgb[0]},${rgb[1]},${rgb[2]},${rgb[3]})`);
      });
    }
  }, [data?.card_image]);

  return (
    <>
      {data?.card_image ? (
        <Box sx={{ width: "95%", margin: "auto" }}>
          <ThreeTwoImg
            sx={{
              mb: 2,
            }}
            src={data?.card_image}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          borderRadius: "8px",
          backgroundColor: "background.paper",
          px: 2,
          pt: 5,
          mt: data?.card_image ? -6 : 0,
          zIndex: -1,
        }}
      >
        <BottomOfHeader />
        <Button
          size="small"
          fullWidth
          variant="contained"
          sx={{ my: 2, mx: "auto" }}
          onClick={() =>
            Navigation.go(
              AdminPages.cardOptions.path
                .replace(":id", id!)
                .replace(":option", "offers"),
            )
          }
        >
          View all offers
        </Button>
      </Box>
    </>
  );
};
