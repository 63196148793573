import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { OutletPanel, RootStyle } from "../auth/AuthLayout.styles";
import logo from "../../assets/svg/logo.svg";

function _MainLayout() {
  const loading = useSelector(uiSelectors.loading);

  return (
    <RootStyle>
      <img style={{ margin: 40 }} src={logo} alt="instaraise logo" />
      <OutletPanel>
        <Outlet />
      </OutletPanel>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </RootStyle>
  );
}

export const MainLayout = React.memo(_MainLayout);
