import {
  InputHTMLAttributes,
  Ref,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";
import { InputMask } from "@react-input/mask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format: string;
}

const PatternFormatCustom = forwardRef(
  (
    props: InputHTMLAttributes<HTMLInputElement> & CustomProps,
    _ref: Ref<HTMLInputElement>,
  ) => {
    const { onChange, ...other } = props;
    return (
      <InputMask
        {...other}
        mask="___ ___ ___"
        onChange={(values) => {
          onChange(values);
        }}
        replacement="_"
      />
    );
  },
);

export const ActivationCodeField = ({
  name = "activation_code",
  label = "Activation code",
}: {
  name?: string;
  format?: string;
  label?: string;
}) => {
  const [field, meta, helpers] = useField(name);
  const { onChange: _, onBlur, ...fieldRest } = field; // discard onChange because NumberFormat change is handled properly via onValueChange

  const [value, setValue] = useState("");
  const formikValueSetter = (value: string, validate?: boolean) => {
    helpers.setValue(value.replace(/\s/g, ""), validate);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    //WARNING: this causes validation to run but makes form less performant
    formikValueSetter(event.target.value);
  };
  const handleBlur = (event: React.FocusEvent<any, Element>) => {
    onBlur(event);
    formikValueSetter(value);
  };
  useEffect(() => {
    if (meta.initialValue) {
      setValue(meta.initialValue);
    }
  }, [meta.initialValue]);

  return (
    <TextField
      {...fieldRest}
      fullWidth
      label={label}
      value={value}
      onChange={handleChange}
      name={name}
      id="formatted-code-input"
      InputProps={{
        inputComponent: PatternFormatCustom as any,
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === "mui-auto-fill" && formikValueSetter(value, true);
        },
      }}
      InputLabelProps={{ shrink: true }}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        //TODO: add space key
        if (e.key == "Enter" || e.key == "Tab") {
          formikValueSetter(value, true);
        }
      }}
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};
