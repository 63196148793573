import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import transfer from "../../assets/svg/transfer.svg";
import { Form, useFormik, yup } from "../../lib";
import { FormStyle } from "../../pages/auth/Auth.styles";
import { PhoneField } from "../fields/PhoneField";
import { useRef } from "react";
import { actions } from "../../state/rtk-query";

const { useTransferCardMutation, useGetCardQuery } = actions;
const validationSchema = yup.object({
  phone: yup
    .string()
    .min(10, "Phone number should be of minimum 10 characters length")
    .required("Phone number is required"),
  agree_to_card_transfer: yup
    .boolean()
    .oneOf([true], 'You must agree to the "Agree to card transfer" checkbox')
    .required("Agree to card transfer is required"),
});

export function TransferCard() {
  const { id } = useParams();
  const [transferCard, { isSuccess }] = useTransferCardMutation();
  const { data } = useGetCardQuery({ id: Number(id) });
  const frm = useFormik({
    initialValues: {
      phone: "",
      agree_to_card_transfer: false,
    },
    validationSchema,
    async onSubmit(values) {
      await transferCard({ body: { phone: values.phone }, id: Number(id!) });
      if (!isSuccess) {
        frm.setFieldValue("agree_to_card_transfer", false);
      }
    },
  });
  const targetRef = useRef<HTMLHeadingElement>(null);

  return (
    <Container maxWidth="md">
      <FormStyle>
        <Form form={frm}>
          <Box sx={{ mt: 6, display: "flex", flexDirection: "column", gap: 2 }}>
            <Box display="flex" justifyContent="center" marginBottom={5}>
              <img src={transfer} width={180} about="" alt="" />
            </Box>
            <Typography variant="h6">Transfer card</Typography>
            <Typography variant="body1">
              Enter the phone number to which you would like to transfer the
              card.
            </Typography>

            <PhoneField />
            <FormControlLabel
              sx={{
                ".MuiButtonBase-root": { paddingTop: 0 },
                alignItems: "flex-start",
                textAlign: "left",
                color: "text.primary",
              }}
              control={<Checkbox checked={frm.values.agree_to_card_transfer} />}
              onChange={(_e, checked) =>
                frm.setFieldValue("agree_to_card_transfer", checked)
              }
              label="I understand that I will no longer have access to this card"
            />
            <Tooltip
              title={
                data?.isPreviewMode
                  ? "Card transfer disabled in preview mode"
                  : ""
              }
              enterTouchDelay={0}
              style={{ zIndex: 1 }}
              placement="top-start"
              arrow
            >
              <div ref={targetRef}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    data?.isPreviewMode ||
                    !frm.dirty ||
                    !frm.isValid ||
                    frm.isSubmitting
                  }
                  fullWidth
                  sx={{
                    mt: 3,
                  }}
                >
                  Transfer card
                </Button>
              </div>
            </Tooltip>
          </Box>
        </Form>
      </FormStyle>
    </Container>
  );
}
