import { styled } from "@mui/material/styles";
import { Paper, Typography, PaperProps } from "@mui/material";
import { drawerWidth } from "./MiniDrawer";
import { useOutlet } from "react-router-dom";
import logo from "../../assets/svg/logo.svg";
import { HamburgerMenu } from "./ProfileMenu";

interface AppBarProps extends PaperProps {
  open?: boolean;
}

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function MobileTopBar() {
  const outlet = useOutlet();
  const pageTitle = outlet?.props.children.props.title;
  return (
    <>
      <PaperBar
        sx={{ borderRadius: 0, py: "10px" }}
        elevation={1}
        className="header"
      >
        <img
          src={logo}
          className="icon"
          alt="instaraise logo"
          style={{ alignSelf: "center" }}
        />
        <HamburgerMenu />
      </PaperBar>
      {pageTitle ? (
        <Typography variant="h4" noWrap component="h1" sx={{ ml: 2, mt: 2 }}>
          {pageTitle}
        </Typography>
      ) : null}
    </>
  );
}
