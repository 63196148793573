import { auth, AuthState } from "./auth/state";
import { ui, UIState } from "./ui/state";
import { admin, AdminState } from "./admin/state";
import { microsoft, MicrosoftState } from "./microsoft/state";
import { AppStateInfo } from "./types";

// Export Actions, Selectors, Types

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./admin/actions";
export * from "./admin/selectors";

export * from "./microsoft/actions";

export interface AppState {
  auth: AuthState;
  ui: UIState;
  admin: AdminState;
  microsoft: MicrosoftState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  ui,
  admin,
  microsoft,
];
export default states;
