import React from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import { useMobile } from "../../themes";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { RouterLink } from "../../lib";

function _HomePage() {
  const isMobile = useMobile();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  return (
    <Box
      sx={{
        width: 1,
        borderRadius: { xs: "20px 20px 0 0", sm: "20px" },
        backgroundColor: "#FFFFFF",
        px: 4,
        pb: 4,
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        fontWeight={500}
        fontSize={isMobile ? 24 : 28}
        sx={{ mt: 4 }}
        textAlign="left"
      >
        Welcome
      </Typography>
      <Typography
        sx={{ mt: 2 }}
        variant="body1"
        gutterBottom
        fontWeight={400}
        textAlign="left"
      >
        Let's get started and find your cards.
      </Typography>
      <Button variant="contained" sx={{ mt: 3 }} fullWidth>
        <Link
          style={{
            color: "#ffffff",
            textDecoration: "none",
            width: "100%",
          }}
          component={RouterLink}
          to={isLoggedIn ? "/admin" : "/auth/login"}
        >
          Access cards
        </Link>
      </Button>
    </Box>
  );
}

export const HomePage = React.memo(_HomePage);
