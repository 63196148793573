import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  styled,
} from "@mui/material";
import { useParams } from "react-router-dom";
import contact_us from "../../assets/svg/contact.svg";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import { useGetCardQuery } from "../../state/rtk-query/state/admin";

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: 16,
  fontSize: 16,
  height: 64,
  borderWidth: 1,
  color: theme.palette.text.secondary,
  borderColor: theme.palette.divider,
  borderStyle: "solid",
  borderRadius: 4,
}));

//Needs minimum of phone or email
export function ContactSupport() {
  const { id } = useParams();
  const { data } = useGetCardQuery({ id: Number(id) });
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 6, display: "flex", flexDirection: "column", gap: 2 }}>
        <Box display="flex" justifyContent="center" marginBottom={5}>
          {data?.support_logo ? (
            <img src={data.support_logo} style={{ maxHeight: 96 }} />
          ) : (
            <img src={contact_us} width={180} about="" alt="" />
          )}
        </Box>
        <Typography variant="h6">Contact support</Typography>
        <Typography variant="body1">
          {data?.support_message ||
            `If you have any questions or need support, please reach out.`}
        </Typography>
        {data?.support_name ? (
          <Card elevation={0}>
            <StyledCardContent>
              <SupportAgentOutlinedIcon />
              {data?.support_name}
            </StyledCardContent>
          </Card>
        ) : null}
        {data?.support_phone ? (
          <Card elevation={0}>
            <StyledCardContent>
              <LocalPhoneOutlinedIcon />
              <Typography
                component="a"
                href={"tel:" + data?.support_phone}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                {data.support_phone}
              </Typography>
            </StyledCardContent>
          </Card>
        ) : null}
        {data?.support_email ? (
          <Card elevation={0}>
            <StyledCardContent>
              <EmailOutlinedIcon />
              <Typography
                component="a"
                href={"mailto:" + data?.support_email}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                {data?.support_email}
              </Typography>
            </StyledCardContent>
          </Card>
        ) : null}
      </Box>
    </Container>
  );
}
