import React, { useEffect } from "react";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import {
  authActions,
  authSelectors,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import Notification from "../../components/Notification";
import { Outlet, useSearchParams } from "react-router-dom";
import { MainContentStyled } from "./AdminLayout.styles";
import { Navigation } from "../../lib";
import { AuthState } from "../../state/auth/state";

export const SimpleAdminLayout = React.memo(function () {
  const loading = useSelector(uiSelectors.loading);
  const requiresMfa = useSelector(authSelectors.requiresMfa);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  useEffect(() => {
    if (params.has("jwt")) {
      const t = params.get("jwt")!;
      const authState: AuthState = {
        token: t,
      };
      dispatch(authActions.setAuthState(authState));
      params.delete("jwt");
      setParams(params);
    }
  }, [params.get("jwt")]);

  useEffect(() => {
    if (requiresMfa === true) {
      Navigation.go("/auth/multi-factor-auth");
    }
  }, [requiresMfa]);

  return (
    <>
      <CssBaseline />
      {/* Figure out if the ml is always correct feels hacky */}
      <MainContentStyled
        sx={{
          pt: 2,
          pl: 0,
          pr: 0,
          pb: 2,
        }}
      >
        {isLoggedIn && !params.has("jwt") && <Outlet />}
      </MainContentStyled>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <CircularProgress size={128} />
        </Backdrop>
      )}
    </>
  );
});
