import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { renderPageRoutes } from "./lib";
import { AuthLayout, AdminLayout } from "./layouts";
import { MainPages } from "./pages/main";
import { AuthPages } from "./pages/auth";
import AdminPages from "./pages/admin";
import OffersPage from "./pages/admin/OffersPage";
import { HistoryRouter } from "./lib/routing/HistoryRouter";
import { authSelectors, useSelector } from "./state";
import { SimpleAdminLayout } from "./layouts/admin/SimpleAdmin";

const ScrollToTop = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, 0);
  }, [location.pathname]);
  return null;
};

export const App = React.memo(function App() {
  const _isLoggedIn = useSelector(authSelectors.isLoggedIn);

  return (
    <HistoryRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/admin" element={<SimpleAdminLayout />}>
          {renderPageRoutes({
            cardOffers: {
              anon: false,
              path: "/admin/card-offers/:id",
              title: "Offers",
              menuOrder: 3,
              view: OffersPage,
            },
          })}
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          {renderPageRoutes(AdminPages)}
        </Route>
        {!_isLoggedIn && (
          <Route path="/auth" element={<AuthLayout />}>
            {renderPageRoutes(AuthPages)}
          </Route>
        )}
        <Route path="/" element={<AuthLayout />}>
          {renderPageRoutes(MainPages)}
        </Route>
      </Routes>
    </HistoryRouter>
  );
});
