import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
export const ContainerStyle = styled(`div`)(() => ({
  // backgroundImage: `url(${pattern})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center right",
  backgroundSize: "auto 100%",
  display: "flex",
  flexDirection: "column",
  // padding: "40px 280px 40px 80px",
  position: "relative",
  width: "inherit",
  // top: -80,
  // [theme.breakpoints.down("sm")]: {
  //   minWidth: "100%",
  // },
}));
export const FormStyle = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  margin: "40px 0px",
}));
export const TextFlexStyle = styled(`div`)(() => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  margin: "7px 0px 20px 0px",
}));
export const CenterElementStyle = styled(`div`)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  margin: "16px 0px 0px 0px",
}));
export const ButtonStyle = styled(Button)(() => ({
  marginBottom: "1rem",
}));
