import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, Media, List } from "../types";

interface AllMedia {
  count: number;
  rows: Media[];
}
interface GoogleCalendar {
  kind: string;
  etag: string;
  id: string;
  summary: string;
  timeZone: string;
  colorId: string;
  backgroundColor: string;
  foregroundColor: string;
  accessRole: string;
  defaultReminders: any[];
  conferenceProperties: any[];
}

interface MicrosoftData {}

export interface AdminState {
  users: List<User>;
  media: AllMedia;
  googleCalendars: GoogleCalendar[];
  microsoftFrontEndData: MicrosoftData[];
  microsoftBackEndData: MicrosoftData[];
  hasOAuthAccessToken: boolean;
  hasMicrosoftAccessToken: boolean;
}

const initialState: AdminState = {
  users: {
    count: 0,
    rows: [],
  },
  media: {
    count: 0,
    rows: [],
  },
  googleCalendars: [],
  microsoftFrontEndData: [],
  microsoftBackEndData: [],
  hasOAuthAccessToken: false,
  hasMicrosoftAccessToken: false,
};

export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {
      setUsers(state, { payload }: PayloadAction<List<User>>) {
        state.users = payload;
      },
      setMedia(state, { payload }: PayloadAction<AllMedia>) {
        state.media = payload;
      },
      setGoogleCalendars(state, { payload }: PayloadAction<GoogleCalendar[]>) {
        state.googleCalendars = payload;
      },
      setMicrosoftFrontEndData(
        state,
        { payload }: PayloadAction<MicrosoftData[]>,
      ) {
        state.microsoftFrontEndData = payload;
      },
      setMicrosoftBackEndData(
        state,
        { payload }: PayloadAction<MicrosoftData[]>,
      ) {
        state.microsoftBackEndData = payload;
      },
      setOAuthAccessToken(state, { payload }: PayloadAction<boolean>) {
        state.hasOAuthAccessToken = payload;
      },
      setMicrosoftAccessToken(state, { payload }: PayloadAction<boolean>) {
        state.hasMicrosoftAccessToken = payload;
      },
    },
  }),
  persist: false,
};
